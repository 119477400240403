import React, { FormEvent } from "react";
import type { TaskData } from "features/tasks/types";
import { DataSources, FormData, FormComponentData, FormFile } from "./types";
import styles from "./TaskForm.module.css";

import { getStore, RootState } from "app/store";
import { selectStaff } from "features/staff/staffSlice";
import { selectSuppliers } from "features/suppliers/suppliersSlice";
import { selectTasks, selectTaskDiaryEntries } from "features/tasks/tasksSlice";
import { selectThermometerCalibrationCategoryIds } from "features/environment/envSlice";
import { CategoryView } from "features/environment/types";
import formClasses from "./formClasses";
import validateForm from "./validateForm";
import { getBatchNumber } from "../functions";

export const STORED_ITEMS_KEY = "storedItems";

function getCategoryTasks(state: RootState, categories: number[]): TaskData[] {
    let tasks = selectTasks(state);
    let categoryTasks = tasks.filter((task) => {
        const categoryId = task.category_id;
        return categoryId && categories.includes(categoryId);
    });

    return categoryTasks;
}

export function getDataSources(task: TaskData): DataSources {
    const state = getStore().getState();
    const allStaff = selectStaff(state);
    const staff = allStaff.map((staff) => {
        return { label: staff.name, value: staff.id };
    });
    const staffWithSignoff = allStaff
        .filter((staff) => staff.app_sign_off === 1)
        .map((staff) => {
            return { label: staff.name, value: staff.id };
        });
    const suppliers = selectSuppliers(state).map((supplier) => {
        return { label: supplier.supplierName, value: supplier.id };
    });
    const thermometerCalibrationCategoryIds =
        selectThermometerCalibrationCategoryIds(state);
    const thermometerTasks = getCategoryTasks(
        state,
        thermometerCalibrationCategoryIds
    ).map((task) => {
        return { label: task.name, value: task.id };
    });

    let batchNumber = 1;
    const taskDiaryEntries = selectTaskDiaryEntries(state, task.id);
    if (taskDiaryEntries) batchNumber = getBatchNumber(taskDiaryEntries);

    let provenCount = task.proven_count;

    return {
        staff,
        staffWithSignoff,
        suppliers,
        thermometerTasks,
        batchNumber,
        provenCount,
    };
}

function createComponents(
    formComponentsData: FormComponentData[],
    formData: FormData,
    categoryView: CategoryView,
    onChange: (model: string, value: any) => void,
    onResetShowErrors: () => void,
    onAddStoredItem: (data: Record<string, any>, resetKeys?: string[]) => void,
    onRemoveStoredItem: (index: number) => void,
    dataSources: DataSources,
    task: TaskData
): [components: JSX.Element[], isValid: boolean] {
    let components = [];
    let [enabledModels, isValid, problems, required, ignore, invalid] =
        validateForm(formData, dataSources, task);
    let count = 0;
    for (let formComponentData of formComponentsData) {
        let value,
            error,
            showPredicate,
            hasProblem,
            isInvalid,
            isRequired,
            sensorReading,
            label,
            files;
        const hasSensor = task.source === "sensor";
        if (
            formComponentData.model &&
            formComponentData.model in enabledModels
        ) {
            ({ value, error, showPredicate, sensorReading, label } =
                enabledModels[formComponentData.model]);
            // Handle multiple components with same model by only including
            // those who pass given showPredicate test.
            if (
                showPredicate &&
                !formComponentData.showPredicates?.includes(showPredicate)
            ) {
                continue;
            }
            hasProblem = problems[formComponentData.model];
            isInvalid = invalid[formComponentData.model];
            isRequired = required[formComponentData.model];
        } else if (formComponentData.model === `_${STORED_ITEMS_KEY}`) {
            value = formData.storedItems;
        } else if (formComponentData.model) {
            // If the component has a model but no data e.g. it's hidden
            continue;
        }

        if (
            formData.files &&
            formComponentData.model &&
            formComponentData.model in formData.files
        ) {
            files = formData.files[formComponentData.model];
        }

        let subComponents: JSX.Element[] | undefined;
        let subIsValid = true;
        if (formComponentData.components) {
            [subComponents, subIsValid] = createComponents(
                formComponentData.components,
                formData,
                categoryView,
                onChange,
                onResetShowErrors,
                onAddStoredItem,
                onRemoveStoredItem,
                dataSources,
                task
            );
            if (!subIsValid) isValid = false;
        }

        let onClick;
        if (formComponentData.onclick) {
            if (formComponentData.onclick === "resetShowErrors") {
                onClick = () => {
                    onResetShowErrors();
                };
            } else if (
                formComponentData.model &&
                formComponentData.onclick === "assign"
            ) {
                let model = formComponentData.model;
                onClick = () => onChange(model, formComponentData.selfValue);
            } else if (
                formComponentData.onclick === "storeItem" &&
                categoryView.taskView.itemsKey
            ) {
                onClick = () => {
                    let itemData: Record<string, any> = {};
                    let resetKeys: string[] = [];
                    Object.keys(enabledModels)
                        .filter((key) => {
                            return (
                                key.indexOf(
                                    `${categoryView.taskView.itemsKey}.`
                                ) !== -1
                            );
                        })
                        .map((key) => {
                            itemData[
                                key.replace(
                                    `${categoryView.taskView.itemsKey}.` || "",
                                    ""
                                )
                            ] = enabledModels[key].value;
                            resetKeys.push(key);
                        });

                    onAddStoredItem(itemData, resetKeys);
                };
            }
        }

        if (formComponentData.type in formClasses) {
            count++;
            let model = formComponentData.model;
            if (!model) model = "";
            let props = {
                ...formComponentData,
                model,
                dataSources,
                value,
                files,
                hasSensor,
                sensorReading,
                error,
                problem: hasProblem,
                invalid: isInvalid,
                required: isRequired,
                components: subComponents,
                name: task.name,
                onChange,
                onClick,
                onRemoveStoredItem,
                key: `component_${count}`,
            };
            if (label) props.label = label;
            const component = React.createElement(
                formClasses[formComponentData.type],
                props
            );

            components.push(component);
        }
    }

    return [components, isValid];
}

interface Props {
    activeUserId?: number;
    hasTaskSignoff?: boolean;
    hasBeenIdle: boolean;
    task: TaskData;
    onChange: (
        data: Record<string, any>,
        filesData: Record<string, FormFile[]>
    ) => void;
    onSubmit: (data: Record<string, any>) => void;
    onResetShowErrors: () => void;
    onError: () => void;
    onAddStoredItem: (data: Record<string, any>) => void;
    onRemoveStoredItem: (index: number) => void;
    formData: FormData;
    categoryView: CategoryView;
}

export default function TaskForm(props: Props) {
    let data = props.formData.data;
    const dataSources = getDataSources(props.task);
    let filesData = props.formData.files;
    if (!filesData) filesData = {}; // Just in case it hasn't been initialised
    const onChange = (model: string, value: any, files?: FormFile[]) => {
        data = {
            ...data,
            [model]: value,
        };

        if (!files) {
            delete filesData[model];
        } else {
            filesData = {
                ...filesData,
                [model]: files,
            };
        }

        props.onChange(data, filesData);
    };

    const [components, isValid] = createComponents(
        props.formData.formView.components,
        props.formData,
        props.categoryView,
        onChange,
        props.onResetShowErrors,
        props.onAddStoredItem,
        props.onRemoveStoredItem,
        dataSources,
        props.task
    );

    const onSubmit = (e?: FormEvent<HTMLFormElement>) => {
        if (e) e.preventDefault();

        if (!isValid) {
            props.onError();
            return;
        }
    };

    return (
        <div>
            <div className={styles.formContainer}>
                <form onSubmit={onSubmit}>{components}</form>
            </div>
        </div>
    );
}
