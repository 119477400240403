import { Dictionary } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { timestamp, KeysWithValuesOfType } from "types";
import type {
    TaskType,
    TaskData,
    TaskStatus,
    Category,
    SensorReading,
    DiaryEntry,
    TaskUrgency,
} from "./types";
import { TaskUrgencies } from "./types";

// Curried function to return a TaskData instances Template and Category
export type TaskObjectsFunction = (task: TaskData) => Category | undefined;

/**
 * Return a function that gets a TaskData instance's category and template.
 *
 * Relies on the assumption that redux ensures templates and categories will only
 * be new instances when new data is added to redux.
 */
export const taskDataMemo = (
    categories: Dictionary<Category>
): TaskObjectsFunction => {
    let memo = new WeakMap<Dictionary<Category>, TaskObjectsFunction>();
    let taskObjectsFunction = memo.get(categories);
    if (taskObjectsFunction) {
        return taskObjectsFunction;
    }

    taskObjectsFunction = (task: TaskData): Category | undefined => {
        let category: Category | undefined = void 0;
        if (task.category_id in categories) {
            category = categories[task.category_id];
        }

        return category;
    };

    memo.set(categories, taskObjectsFunction);

    return taskObjectsFunction;
};

export const getDueTasks = (
    tasks: TaskData[],
    getTaskObjects: TaskObjectsFunction,
    status?: TaskStatus
): TaskData[] => {
    let filteredTasks = [];
    for (let i = 0; i < tasks.length; i++) {
        const task = tasks[i];
        const category = getTaskObjects(task);

        if (!category) continue;

        if (!task.schedule) continue;

        // If no lastDue, task was created more recently than lastDue date?
        // Could be undefined so specifically check if false.
        // If undefined then assume no recent diary entries.
        if (task.lastDue === false) {
            continue;
        }
        // has it been performed
        if (task.isPerformed) {
            continue;
        }
        // Due
        if (!status || (status === "due" && task.isDue)) {
            filteredTasks.push(task);
        }
        // urgent
        if (!status || (status === "urgent" && !task.isDue)) {
            filteredTasks.push(task);
        }
    }

    return filteredTasks;
};

export const computeTypeTasks = (tasks: TaskData[], type: TaskType) => {
    let filteredTasks = [];

    for (let i = 0; i < tasks.length; i++) {
        const task = tasks[i];
        if (type === "schedule" && !task.schedule) continue;
        if (
            type === "event" &&
            (task.schedule ||
                (task.urgency && task.urgency === TaskUrgencies.DUE))
        )
            continue;

        if (
            type === "frequent" &&
            (!task.urgency || task.urgency !== TaskUrgencies.FREQUENT)
        )
            continue;

        filteredTasks.push(task);
    }

    return filteredTasks;
};

export const sortOrderedCategories = (
    categoryA: Category | undefined,
    categoryB: Category | undefined
) => {
    if (!categoryA || !categoryB) return 0;
    if (!categoryA && categoryB) return 1;
    if (categoryA && !categoryB) return -1;

    let comparison = (categoryA.rank || 100) - (categoryB.rank || 100);
    if (comparison === 0)
        comparison = categoryA.name.localeCompare(categoryB.name);

    return comparison;
};

export const sortCategoryGroups = (
    groupA: TaskData[],
    groupB: TaskData[],
    getTaskObjects: TaskObjectsFunction
) => {
    const categoryA = groupCategory(groupA, getTaskObjects);
    const categoryB = groupCategory(groupB, getTaskObjects);

    return sortOrderedCategories(categoryA, categoryB);
};

export const getUrgency = (task: TaskData): TaskUrgency => {
    let urgency: TaskUrgency = TaskUrgencies.OTHER;
    if (task.urgency) {
        urgency = task.urgency;
    } else if (task.schedule && task.lastDue !== false && !task.isPerformed) {
        urgency = task.isDue ? TaskUrgencies.DUE : TaskUrgencies.URGENT;
    }

    return urgency;
};

export const getGroupUrgency = (group: TaskData[]): TaskUrgency => {
    let urgency: TaskUrgency = TaskUrgencies.OTHER;
    for (let task of group) {
        const taskUrgency = getUrgency(task);
        if (taskUrgency < urgency) {
            urgency = taskUrgency;
        }
    }

    return urgency;
};

interface CategoryTasks {
    urgency: TaskUrgency;
    tasks: TaskData[];
}
export const computeUrgencyTasks = (
    tasks: TaskData[]
): Dictionary<TaskData[]> => {
    let categoryTasksList: Dictionary<CategoryTasks> = {};
    let urgencyTasks: Dictionary<TaskData[]> = {};

    for (let i = 0; i < tasks.length; i++) {
        const task = tasks[i];
        const id = task.schedule ? task.category_id : `E${task.category_id}`;
        const taskList = categoryTasksList;
        let urgency = getUrgency(task);

        const validScheduleUrgencies = [
            TaskUrgencies.DUE,
            TaskUrgencies.URGENT,
        ] as Array<number>;
        if (task.schedule && !validScheduleUrgencies.includes(urgency)) {
            continue;
        }

        let categoryTasks = taskList[id];
        if (!categoryTasks) {
            categoryTasks = {
                urgency: urgency,
                tasks: [],
            };

            taskList[id] = categoryTasks;
        }

        if (categoryTasks.urgency > urgency) {
            categoryTasks.urgency = urgency;
        }

        categoryTasks.tasks.push(task);
    }

    for (let categoryId in categoryTasksList) {
        const categoryTasks = categoryTasksList[categoryId] as CategoryTasks;
        if (!urgencyTasks.hasOwnProperty(categoryTasks.urgency)) {
            urgencyTasks[categoryTasks.urgency] = [];
        }

        urgencyTasks[categoryTasks.urgency] = urgencyTasks[
            categoryTasks.urgency
        ]?.concat(categoryTasks.tasks);
    }

    return urgencyTasks;
};

function compareTaskData(
    field: KeysWithValuesOfType<TaskData, string | number | undefined>,
    taskA: TaskData,
    taskB: TaskData
): number {
    if (!(field in taskA) && !(field in taskB)) return 0;

    let fieldA = taskA[field];
    if (typeof fieldA === "string") fieldA = fieldA.toUpperCase();
    let fieldB = taskB[field];
    if (typeof fieldB === "string") fieldB = fieldB.toUpperCase();

    if ((fieldA !== 0 && !fieldA) || (fieldB !== 0 && !fieldB)) return 0;
    if (fieldA !== 0 && !fieldA && fieldB) return 1;
    if (fieldA && fieldB !== 0 && !fieldB) return -1;

    if ((fieldA !== 0 && !fieldA) || fieldA < fieldB) {
        return -1;
    }
    if ((fieldB !== 0 && !fieldB) || fieldA > fieldB) {
        return 1;
    }

    // fields are equal
    return 0;
}

export const sortTasks = (
    tasks: TaskData[],
    field1: KeysWithValuesOfType<TaskData, string | number | undefined>,
    field2?: KeysWithValuesOfType<TaskData, string | number | undefined>,
    field3?: KeysWithValuesOfType<TaskData, string | number | undefined>
): TaskData[] => {
    if (field1 === void 0) field1 = "name";
    if (!tasks) return [];
    return tasks.slice().sort(function (taskA, taskB) {
        let comparison = compareTaskData(field1, taskA, taskB);
        if (comparison === 0 && field2) {
            comparison = compareTaskData(field2, taskA, taskB);
            if (comparison === 0 && field3) {
                comparison = compareTaskData(field3, taskA, taskB);
            }
        }

        return comparison;
    });
};

export const computeGroups = (
    tasks: TaskData[],
    getTaskObjects: TaskObjectsFunction
): TaskData[][] => {
    let groups: Array<Array<TaskData>> = [];
    let categoryGroups: Record<string, TaskData[]> = {};

    for (let task of tasks) {
        const category = getTaskObjects(task);
        if (!category) continue;

        const id = task.schedule ? task.category_id : `E${task.category_id}`;

        if (!categoryGroups[id]) {
            categoryGroups[id] = [];
        }

        categoryGroups[id].push(task);
    }

    groups = Object.values(categoryGroups);

    return groups;
};

export const getLastPerformedMessage = (
    lastPerformed: timestamp,
    timeLimit = 0,
    isUTC = false
) => {
    const nowDate = new Date();
    let now = nowDate.getTime() / 1000;
    if (!isUTC) {
        // lastPerformed time is a unix timestamp, hence has no timezone.
        // To use it in a comparison, we need to create a new date
        // without the browser forcing a local timezone conversion.
        now =
            Date.UTC(
                nowDate.getFullYear(),
                nowDate.getMonth(),
                nowDate.getDate(),
                nowDate.getHours(),
                nowDate.getMinutes(),
                nowDate.getSeconds()
            ) / 1000;
    }
    const duration = now - lastPerformed;
    if (timeLimit && duration > timeLimit) {
        return null;
    }
    let lastPerformedMessage;
    const durationMinutes = Math.round(duration / 60);
    const minSuffix = (minutes: number) => {
        return minutes > 1 ? "mins" : "min";
    };
    if (durationMinutes < 1) {
        lastPerformedMessage = "just now";
    } else if (durationMinutes < 60) {
        lastPerformedMessage =
            durationMinutes.toFixed(0) +
            " " +
            minSuffix(durationMinutes) +
            " ago";
    } else if (durationMinutes < 24 * 60) {
        const hours = Math.floor(durationMinutes / 60);
        const hoursSuffix = hours > 1 ? "hrs" : "hr";
        const minutes = durationMinutes % 60;
        lastPerformedMessage = hours + " " + hoursSuffix;
        if (minutes > 0)
            lastPerformedMessage += " " + minutes + " " + minSuffix(minutes);
        lastPerformedMessage += " ago";
    } else {
        // Moment causes problems with timestamps so force
        // it to treat as UTC to prevent TZ conversions.
        // var date = moment.unix(lastPerformed).utc();
        const date = DateTime.fromSeconds(lastPerformed).toUTC();
        // var nowDate = DateTime.now();
        let format = "d MMM";
        if (date.year !== nowDate.getFullYear()) {
            format += ", yyyy";
        }
        lastPerformedMessage = date.toFormat(format);
    }

    return lastPerformedMessage;
};

export const getFormattedDuration = (
    time: timestamp,
    timeLimit = 0,
    isUTC = false
): string => {
    const nowDate = new Date();
    let now = nowDate.getTime() / 1000;
    if (!isUTC) {
        // A unix timestamp has no timezone.
        // So if not already UTC, to use it in a comparison in we need to create a new date
        // without the browser forcing a local timezone conversion.
        now =
            Date.UTC(
                nowDate.getFullYear(),
                nowDate.getMonth(),
                nowDate.getDate(),
                nowDate.getHours(),
                nowDate.getMinutes(),
                nowDate.getSeconds()
            ) / 1000;
    }
    let inPast = true;
    let duration;
    if (now > time) {
        duration = now - time;
    } else {
        duration = time - now;
        inPast = false;
    }

    if (timeLimit && duration > timeLimit) {
        return "";
    }
    let message;
    const durationMinutes = Math.round(duration / 60);
    const minSuffix = (minutes: number) => {
        return minutes > 1 ? "mins" : "min";
    };
    const suffix = inPast ? " ago" : "";
    if (inPast && durationMinutes < 1) {
        message = "just now";
    } else if (durationMinutes < 60) {
        message =
            durationMinutes.toFixed(0) +
            " " +
            minSuffix(durationMinutes) +
            suffix;
    } else if (durationMinutes < 24 * 60) {
        const hours = Math.floor(durationMinutes / 60);
        const hoursSuffix = hours > 1 ? "hrs" : "hr";
        const minutes = durationMinutes % 60;
        message = hours + " " + hoursSuffix;
        if (minutes > 0) message += " " + minutes + " " + minSuffix(minutes);
        message += suffix;
    } else {
        const days = Math.floor(durationMinutes / (24 * 60));
        if (days > 31) {
            const months = Math.floor(days / 31);
            let unit = months > 1 ? "months" : "month";
            message = months.toFixed(0) + " " + unit + suffix;
        } else {
            let unit = days > 1 ? "days" : "day";
            message = days.toFixed(0) + " " + unit + suffix;
        }
    }

    return message;
};

export const lastRecording = (
    group: TaskData[],
    getTaskObjects: TaskObjectsFunction
): string => {
    let lastRecording = 0;
    let categoryName;

    for (var i = 0; i < group.length; i++) {
        var task = group[i];
        const category = getTaskObjects(task);
        if (task && task.performed && task.performed > lastRecording) {
            lastRecording = task.performed;
            categoryName = category?.name;
        }
    }

    if (lastRecording !== 0) {
        var timeLimit;
        // Only show for Hot-held Food if its not older than 6 hours
        if (categoryName && categoryName === "Hot-held Food") {
            timeLimit = 21600;
        }
        var lastPerformedMessage = getLastPerformedMessage(
            lastRecording,
            timeLimit
        );
        if (lastPerformedMessage) {
            return "Last performed: " + lastPerformedMessage;
        }
    }

    return "";
};

export const groupName = (
    group: TaskData[],
    getTaskObjects: TaskObjectsFunction
): string => {
    const category = groupData(group, getTaskObjects);
    return category?.name || "<empty>";
};

export const groupData = (
    group: TaskData[],
    getTaskObjects: TaskObjectsFunction
): Category | undefined => {
    if (group !== undefined && group.length > 0) {
        const firstTask = group[0];
        return getTaskObjects(firstTask);
    }
    return undefined;
};

export const groupCategory = (
    group: TaskData[],
    getTaskObjects: TaskObjectsFunction
): Category | undefined => {
    const category = groupData(group, getTaskObjects);

    return category;
};

let _groupCount = 0;
export const groupId = (group: TaskData[]): string | undefined => {
    if (group !== undefined && group.length > 0) {
        const firstTask = group[0];
        _groupCount++;
        return `task_${firstTask.id}_${_groupCount}`;
    }
    return undefined;
};

export function oldReading(reading?: SensorReading): boolean {
    return !!reading && Date.now() / 1000 - reading.timestamp > 7200;
}

function areSameDate(date1: Date, date2: Date): boolean {
    return (
        date1.getDate() === date2.getUTCDate() &&
        date1.getMonth() === date2.getUTCMonth() &&
        date1.getFullYear() === date2.getUTCFullYear()
    );
}

export function getBatchNumber(diaries: DiaryEntry[]): number {
    let count = 1;

    const now = new Date();
    const countedDiaryIds: Array<string> = [];
    for (let i = 0; i < diaries.length; i++) {
        const diary = diaries[i];
        if (countedDiaryIds.includes(diary.uuid)) continue;

        let performedOn;
        // diary.performed_on is in UTC but is actually the local time
        // when the diary entry was created.
        if (diary.performedOn) {
            performedOn = new Date(diary.performedOn);
        } else {
            // This happens when the diary has been created locally,
            // not returned from the server.
            performedOn = new Date(diary.performed * 1000);
        }

        if (!areSameDate(now, performedOn)) continue;
        countedDiaryIds.push(diary.uuid);

        count++;
    }

    return count;
}
