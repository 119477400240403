import {
    createSlice,
    createEntityAdapter,
    EntityState,
    PayloadAction,
    Dictionary,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { TaskData, TaskPerformedData, Category, DiaryEntry } from "./types";
import { CategoryView } from "features/environment/types";
import { selectCategoryView } from "features/environment/envSlice";
import { UUID } from "types";

type taskId = number;
type venueId = number;

export interface TasksState {
    tasks: EntityState<TaskData>;
    tasksPerformed: EntityState<TaskPerformedData>;
    categories: EntityState<Category>;
    taskDiaryEntries: Record<taskId, DiaryEntry[]>;
    diaryEntries: Record<venueId, Record<UUID, DiaryEntry>>;
    taskConfigs: Record<taskId, CategoryView>;
}

const tasksDataAdapter = createEntityAdapter<TaskData>();
const tasksPerformedDataAdapter = createEntityAdapter<TaskPerformedData>({
    selectId: (performed) => performed.taskId,
});
const categoriesAdapter = createEntityAdapter<Category>();

const initialState: TasksState = {
    tasks: tasksDataAdapter.getInitialState(),
    tasksPerformed: tasksPerformedDataAdapter.getInitialState(),
    categories: categoriesAdapter.getInitialState(),
    taskDiaryEntries: {},
    diaryEntries: {},
    taskConfigs: {},
};

// How long should diaries be kept for
export const DEFAULT_DIARY_CUTOFF =
    new Date().getTime() - 60 * 60 * 24 * 7 * 1000; // 7 days

export const tasksSlice = createSlice({
    name: "tasks",
    initialState,
    reducers: {
        setTask: (state: TasksState, action: PayloadAction<TaskData>) => {
            tasksDataAdapter.setOne(state.tasks, action.payload);
        },
        setTasks: (state: TasksState, action: PayloadAction<TaskData[]>) => {
            tasksDataAdapter.setAll(state.tasks, action.payload);
        },
        setTaskPerformed: (
            state: TasksState,
            action: PayloadAction<TaskPerformedData>
        ) => {
            tasksPerformedDataAdapter.setOne(
                state.tasksPerformed,
                action.payload
            );
        },
        setTasksPerformed: (
            state: TasksState,
            action: PayloadAction<TaskPerformedData[]>
        ) => {
            tasksPerformedDataAdapter.setAll(
                state.tasksPerformed,
                action.payload
            );
        },
        updateTask: (state: TasksState, action: PayloadAction<TaskData>) => {
            tasksDataAdapter.setOne(state.tasks, action.payload);
        },
        updateTasks: (
            state: TasksState,
            action: PayloadAction<Record<number, TaskData>>
        ) => {
            tasksDataAdapter.setMany(state.tasks, action.payload);
        },
        setCategories: (
            state: TasksState,
            action: PayloadAction<Category[]>
        ) => {
            categoriesAdapter.setAll(state.categories, action.payload);
        },
        setTaskDiaryEntries: (
            state: TasksState,
            action: PayloadAction<[taskId, DiaryEntry[]]>
        ) => {
            let [taskId, diaryEntries] = action.payload;
            state.taskDiaryEntries[taskId] = diaryEntries;
        },
        addTaskDiaryEntries: (
            state: TasksState,
            action: PayloadAction<DiaryEntry[]>
        ) => {
            const diaryEntries = action.payload;

            outerLoop: for (let diaryEntry of diaryEntries) {
                const taskId = diaryEntry.taskId;
                if (!state.taskDiaryEntries[taskId]) {
                    state.taskDiaryEntries[taskId] = [];
                }
                for (let diary of state.taskDiaryEntries[taskId]) {
                    if (diary.uuid === diaryEntry.uuid) {
                        // skip updating state.taskDiaryEntries below
                        continue outerLoop;
                    }
                }
                state.taskDiaryEntries[taskId].push(diaryEntry);
            }
        },
        removeTaskDiaryEntriesByDate: (
            state: TasksState,
            action: PayloadAction<number>
        ) => {
            const cutoff = action.payload;
            for (let taskId in state.taskDiaryEntries) {
                state.taskDiaryEntries[taskId] = state.taskDiaryEntries[
                    taskId
                ].filter((diary) => {
                    let performedOn: string | number | undefined =
                        diary.performedOn;
                    if (!performedOn && diary.performed)
                        performedOn = diary.performed * 1000;
                    return (
                        performedOn && new Date(performedOn).getTime() > cutoff
                    );
                });
            }
        },
        setDiaryEntries: (
            state: TasksState,
            action: PayloadAction<DiaryEntry[]>
        ) => {
            if (action.payload.length === 0) return;
            const diaryEntry = action.payload[0];
            const venueId = diaryEntry.venueId;
            let diariesObject: Record<UUID, DiaryEntry> = {};
            diariesObject = action.payload.reduce((prevValue, currentValue) => {
                prevValue[currentValue.uuid] = currentValue;
                return prevValue;
            }, diariesObject);

            state.diaryEntries[venueId] = diariesObject;
        },
        addDiaryEntries: (
            state: TasksState,
            action: PayloadAction<DiaryEntry[]>
        ) => {
            if (action.payload.length === 0) return;

            const diaryEntry = action.payload[0];
            const venueId = diaryEntry.venueId;
            let diariesObject: Record<UUID, DiaryEntry> = {};
            diariesObject = action.payload.reduce((prevValue, currentValue) => {
                prevValue[currentValue.uuid] = currentValue;
                return prevValue;
            }, diariesObject);

            if (!state.diaryEntries[venueId]) {
                state.diaryEntries[venueId] = {};
            }
            state.diaryEntries[venueId] = Object.assign(
                state.diaryEntries[venueId],
                diariesObject
            );
        },
        removeDiaryEntries: (
            state: TasksState,
            action: PayloadAction<[number, DiaryEntry[]]>
        ) => {
            const [venueId, diaryEntries] = action.payload;
            if (venueId in state.diaryEntries) {
                for (let diary of diaryEntries) {
                    if (diary.uuid in state.diaryEntries[venueId]) {
                        delete state.diaryEntries[venueId][diary.uuid];
                    }
                }
            }
        },
        removeDiaryEntriesByDate: (
            state: TasksState,
            action: PayloadAction<number>
        ) => {
            const cutoff = action.payload;
            for (let venueId in state.diaryEntries) {
                state.diaryEntries[venueId] = Object.fromEntries(
                    Object.entries(state.diaryEntries[venueId]).filter(
                        ([uuid, diary]) => {
                            let performedOn: string | number | undefined =
                                diary.performedOn;
                            if (!performedOn && diary.performed)
                                performedOn = diary.performed * 1000;
                            return (
                                performedOn &&
                                new Date(performedOn).getTime() > cutoff
                            );
                        }
                    )
                );
            }
        },
        resetState: (state: TasksState, action: PayloadAction<undefined>) => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setTask,
    setTasks,
    updateTask,
    updateTasks,
    setTaskPerformed,
    setTasksPerformed,
    setCategories,
    setDiaryEntries,
    addDiaryEntries,
    setTaskDiaryEntries,
    addTaskDiaryEntries,
    removeTaskDiaryEntriesByDate,
    removeDiaryEntries,
    removeDiaryEntriesByDate,
} = tasksSlice.actions;

const tasksSelectors = tasksDataAdapter.getSelectors();
const tasksPerformedSelectors = tasksPerformedDataAdapter.getSelectors();
const catergoriesSelectors = categoriesAdapter.getSelectors();

export const selectTasks = (
    state: RootState,
    taskIds?: number[]
): TaskData[] => {
    let tasks = tasksSelectors.selectAll(state.tasks.tasks);
    if (taskIds) {
        tasks = tasks.filter((task) => task.id in taskIds);
    }
    return tasks;
};
export const selectTask = (
    state: RootState,
    taskId: number
): TaskData | undefined => {
    if (taskId in state.tasks.tasks.entities) {
        return state.tasks.tasks.entities[taskId];
    } else {
        return void 0;
    }
};

export const selectTasksPerformed = (
    state: RootState
): TaskPerformedData[] | undefined => {
    return tasksPerformedSelectors.selectAll(state.tasks.tasksPerformed);
};
export const selectTasksPerformedEntities = (
    state: RootState
): Dictionary<TaskPerformedData> => {
    return tasksPerformedSelectors.selectEntities(state.tasks.tasksPerformed);
};
export const selectTaskPerformed = (
    state: RootState,
    taskId: number
): TaskPerformedData | undefined => {
    return state.tasks.tasksPerformed.entities[taskId];
};

export const selectCategories = (state: RootState) =>
    catergoriesSelectors.selectAll(state.tasks.categories);
export const selectCategoryEntities = (state: RootState) =>
    catergoriesSelectors.selectEntities(state.tasks.categories);
export const selectCategory = (state: RootState, categoryId: number) => {
    return state.tasks.categories.entities[categoryId];
};

export const selectTaskDiaryEntries = (
    state: RootState,
    taskId: number
): DiaryEntry[] | undefined => {
    return state.tasks.taskDiaryEntries[taskId];
};

export const selectDiaryEntries = (
    state: RootState,
    venueId: number
): Record<UUID, DiaryEntry> | undefined => {
    return state.tasks.diaryEntries[venueId];
};
export default tasksSlice.reducer;

export const selectHasSetData = (state: RootState) => {
    return (
        state.tasks.tasks !== tasksDataAdapter.getInitialState() &&
        state.tasks.tasksPerformed !==
            tasksPerformedDataAdapter.getInitialState() &&
        state.tasks.categories !== categoriesAdapter.getInitialState()
    );
};
