import type { DiaryEntry, TaskData } from "./types";
import { setActiveTasksScreen } from "app/appSlice";
import { useCallback } from "react";
import { useAppDispatch } from "app/hooks";
import validateForm from "./form/validateForm";
import { getDataSources } from "./form/TaskForm";
import getTaskFormConfig from "./form/getTaskFormConfig";
import { getStore } from "app/store";
import { selectVenueId } from "features/venue/venueSlice";
import { selectDiaryEntries } from "./tasksSlice";
import { appSelect, appSelectArg } from "app/util";
import type { CategoryView } from "features/environment/types";
import type { PopoverInformation } from "features/environment/types";

export interface TaskGroupPopoverData {
    recentDiaries?: DiaryEntry[];
    showPopoverInformation?: PopoverInformation;
    selectedTask: (task: TaskData) => void;
    getDiaryEntryHasErrors: (task: TaskData, diaryEntry: DiaryEntry) => boolean;
    clickedOutside?: () => void;
}

export default function useTaskGroupPopover(
    categoryView?: CategoryView
): TaskGroupPopoverData {
    const dispatch = useAppDispatch();

    let diaryEntries: Record<number, DiaryEntry> | undefined;
    let showPopoverInformation: PopoverInformation | undefined;
    if (categoryView?.showPopoverInformation) {
        let venueId = appSelect(selectVenueId);
        if (venueId) {
            diaryEntries = appSelectArg(selectDiaryEntries, venueId);
            showPopoverInformation = categoryView.showPopoverInformation;
        }
    }

    const selectedTask = useCallback(
        (task: TaskData) => {
            dispatch(
                setActiveTasksScreen({
                    taskId: task.id,
                })
            );
        },
        [dispatch]
    );

    const getDiaryEntryHasErrors = useCallback(
        (task: TaskData, diaryEntry: DiaryEntry) => {
            const formConfig = getTaskFormConfig(task, getStore().getState());
            if (!formConfig) return false;

            const dataSources = getDataSources(task);
            const formData = {
                taskId: task.id,
                formView: formConfig.taskView,
                data: JSON.parse(diaryEntry.information),
                files: {},
            };
            let [_enabledModels, _isValid, problems, _required] = validateForm(
                formData,
                dataSources,
                task
            );

            return Object.values(problems).length > 0;
        },
        []
    );

    const clickedOutside = useCallback(() => {
        dispatch(setActiveTasksScreen({}));
    }, [dispatch]);

    return {
        recentDiaries: diaryEntries ? Object.values(diaryEntries) : void 0,
        showPopoverInformation,
        selectedTask,
        getDiaryEntryHasErrors,
        clickedOutside,
    };
}
