import {
    createApi,
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { PayloadAction } from "@reduxjs/toolkit";
import { SQLDatetime } from "types";
import { RootState } from "app/store";
import {
    selectBearerToken,
    selectFernetToken,
} from "features/loginToken/loginTokenSlice";
import type { UserVenueData } from "features/loginToken/types";
import { selectEnv, selectUniqueId } from "features/environment/envSlice";
import { selectActiveVenueId } from "app/appSlice";
import type { ServerError } from "app/appSlice";
import { VenueData } from "features/venue/types";
import { Supplier } from "features/suppliers/types";
import { Staff } from "features/staff/types";
import {
    Category,
    DiaryEntry,
    DiaryFile,
    TaskData,
} from "features/tasks/types";
import { QueryState, getQueryState } from "./util";
import { version } from "VERSION";
import { detect } from "detect-browser";

const ENV_TIMEOUT = 10; // seconds
const ENV_WAIT = 300; // milliseconds

const tokenBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let env = selectEnv(api.getState() as RootState);
    let retries = 0;
    while (
        (!env || !env.appServiceBaseUrl) &&
        retries * ENV_WAIT < ENV_TIMEOUT * 1000
    ) {
        await new Promise((resolve) => setTimeout(resolve, ENV_WAIT));
        env = selectEnv(api.getState() as RootState);
        retries++;
    }

    if (!env || !("appServiceBaseUrl" in env)) {
        return {
            error: {
                status: 400,
                statusText: "Bad Request",
                data: "Missing appServiceBaseUrl in env",
            },
        };
    }

    const appServiceBaseUrl = env.appServiceBaseUrl;

    return fetchBaseQuery({
        baseUrl: appServiceBaseUrl,
        prepareHeaders: (headers, { getState }) => {
            const state = getState() as RootState;
            const fernetToken = selectFernetToken(state);
            if (fernetToken) {
                headers.append("Authorization", `Basic ${fernetToken}`);
            } else {
                const bearerToken = selectBearerToken(state);
                if (bearerToken) {
                    headers.append(
                        "Authorization",
                        `Bearer ${bearerToken.token}`
                    );
                }
            }

            return headers;
        },
    })(args, api, extraOptions);
};

interface DiaryEntryResponse extends DiaryEntry {
    venue_id: number;
    task_id: number;
    performed_on: SQLDatetime;
}

interface UserVenueDataResponse extends UserVenueData {
    company_id: number;
    start_date: number;
    audit_date: string;
    app_version: string;
    app_version_updated: string;
    app_last_connected: string;
    has_task_signoff: 1 | 0;
    created_at: string;
    updated_at: string;
    staff_count: number;
}

export interface TasksResponse {
    categories: Record<number, Category>;
    tasks: TaskData[];
}

interface VenueDataQueryVars {
    venueId: number;
    uniqueId: string;
    browser: string;
}

interface TaskDiariesQueryVars {
    tasks: TaskData[];
    venueId: number;
}

export const appServiceApi = createApi({
    reducerPath: "appServiceApi",
    baseQuery: tokenBaseQuery,
    endpoints: (builder) => ({
        getVenues: builder.query<UserVenueData[], void>({
            query: () => `venues`,
            transformResponse: (response, meta) => {
                let venues = response as UserVenueDataResponse[];
                venues = venues.map((venue) => {
                    return {
                        ...venue,
                        companyId: venue.company_id,
                        startDate: venue.start_date,
                        auditDate: venue.audit_date,
                        appVersion: venue.app_version,
                        appVersionUpdated: venue.app_version_updated,
                        appLastConnected: venue.app_last_connected,
                        hasTaskSignoff: venue.has_task_signoff === 1,
                        createdAt: venue.created_at,
                        updatedAt: venue.updated_at,
                        staffCount: venue.staff_count,
                    };
                });

                return venues;
            },
        }),
        getVenueData: builder.query<VenueData, VenueDataQueryVars>({
            query: (data: VenueDataQueryVars) => {
                return `${data.venueId}/state?device=${data.uniqueId}&appVersion=${version}&browser=${data.browser}`;
            },
        }),
        getTasks: builder.query<TaskData[], number>({
            query: (venueId) => `${venueId}/tasks?appVersion=${version}`,
        }),
        getCategories: builder.query<Category[], number>({
            query: (venueId) => `${venueId}/categories?appVersion=${version}`,
        }),
        getSuppliers: builder.query<Supplier[], number>({
            query: (venueId) => `${venueId}/suppliers`,
        }),
        submitDebugReport: builder.query<Supplier[], number>({
            query: (venueId) => `${venueId}/suppliers`,
        }),
        getStaff: builder.query<Staff[], number>({
            query: (venueId) => `${venueId}/staff`,
        }),
        getTaskDiaries: builder.query<DiaryEntry[], TaskDiariesQueryVars>({
            query: (data) => {
                const venueId = data.venueId;
                let params = data.tasks
                    .map((task) => `tasks[]=${task.id}`)
                    .join("&");
                return `${venueId}/diaries?${params}`;
            },
            transformResponse: (response, meta) => {
                let diaries = response as DiaryEntryResponse[];
                diaries = diaries.map((diary) => {
                    return {
                        ...diary,
                        venueId: diary.venue_id,
                        taskId: diary.task_id,
                        performedOn: diary.performed_on,
                    };
                });

                return diaries;
            },
        }),
        // TODO: send diaries in batches in case lots of diaries are queued
        sendDiaries: builder.mutation<DiaryEntry[], DiaryEntry[]>({
            query: (diaries) => {
                console.log(
                    diaries.length +
                        " diary entries queued for sending to the cloud"
                );
                const venueId = diaries[0].venueId;
                // it appears that sometimes the "venueId" is not available
                // appearing as "undefined" in the logs
                // what would be the cause of this?  are the diary entries faulty
                // and therefore blocking the sending of diary entries completely?
                if (!venueId) {
                    console.log(
                        "Missing venue id when trying to write diary entries!"
                    );
                    console.log(diaries[0]);
                } else {
                    console.log("Writing diary entries for venue " + venueId);
                    console.log(diaries[0]);
                }
                return {
                    url: `${venueId}/diaries`,
                    method: "POST",
                    body: diaries,
                    responseHandler: "text",
                };
            },
            transformResponse: (_response, _meta, diaries) => {
                return diaries;
            },
        }),
        sendFile: builder.mutation<DiaryFile, DiaryFile>({
            query: (file) => {
                const venueId = file.venueId;
                if (!venueId) {
                    console.log(
                        "Missing venue id when trying to write diary files!"
                    );
                    console.log(file);
                }

                const binary = atob(file.file);
                const arrayBuffer = Uint8Array.from(binary, (c) =>
                    c.charCodeAt(0)
                ).buffer;
                const blob = new Blob([arrayBuffer], { type: file.mimeType });

                let fileForm = new FormData();
                fileForm.append("filename", file.filename);
                fileForm.append("diary_entry", file.diary_entry);
                fileForm.append("file", blob);
                return {
                    url: `${venueId}/file`,
                    method: "POST",
                    body: fileForm,
                    responseHandler: "text",
                };
            },
            transformResponse: (_response, _meta, file) => {
                return file;
            },
        }),
    }),
});

export const {
    useGetVenueDataQuery,
    useGetTasksQuery,
    useGetSuppliersQuery,
    useGetStaffQuery,
} = appServiceApi;

export const selectVenueDataQueryState = (
    state: RootState
): QueryState | undefined => {
    const venueId = selectActiveVenueId(state);
    const uniqueId = selectUniqueId(state);
    if (venueId && uniqueId) {
        let browser = "";
        return getQueryState(
            appServiceApi.endpoints.getVenueData.select({
                venueId,
                uniqueId,
                browser,
            })(state)
        );
    }

    return;
};

export const selectTasksDataQueryState = (
    state: RootState
): QueryState | undefined => {
    const venueId = selectActiveVenueId(state);
    if (venueId) {
        return getQueryState(
            appServiceApi.endpoints.getTasks.select(venueId)(state)
        );
    } else {
        return;
    }
};

export const selectStaffQueryState = (
    state: RootState
): QueryState | undefined => {
    const venueId = selectActiveVenueId(state);
    if (venueId) {
        return getQueryState(
            appServiceApi.endpoints.getStaff.select(venueId)(state)
        );
    } else {
        return;
    }
};

export const selectSuppliersQueryState = (
    state: RootState
): QueryState | undefined => {
    const venueId = selectActiveVenueId(state);
    if (venueId) {
        return getQueryState(
            appServiceApi.endpoints.getSuppliers.select(venueId)(state)
        );
    } else {
        return;
    }
};

export const selectDebugReportQueryState = (
    state: RootState
): QueryState | undefined => {
    const venueId = selectActiveVenueId(state);
    if (venueId) {
        return getQueryState(
            appServiceApi.endpoints.submitDebugReport.select(venueId)(state)
        );
    } else {
        return;
    }
};

export const getServerError = (
    action: PayloadAction<any>
): ServerError | undefined => {
    if (!action.payload?.data || !action.payload?.status) return;

    const data = action.payload.data;
    const status = action.payload.status;
    const datetime = new Date().toISOString();
    const serverError = {
        httpCode: Number(status),
        data,
        datetime,
    };

    return serverError;
};
