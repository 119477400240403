import { useCallback } from "react";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { VenueData } from "features/venue/types";
import {
    selectActiveVenueId,
    selectReloadingPlan,
    selectReloadingTasks,
    selectReloadingStaff,
    selectReloadingSuppliers,
    selectDebugReport,
    setReloadingPlan,
    setReloadingTasks,
    setReloadingStaff,
    setReloadingSuppliers,
    setDebugReport,
    addAppToast,
    setShowResetConfirmation,
} from "app/appSlice";
import { selectVenueData } from "features/venue/venueSlice";
import { selectOnline } from "features/environment/envSlice";
import {
    selectTasksDataQueryState,
    selectStaffQueryState,
    selectSuppliersQueryState,
    selectDebugReportQueryState,
} from "services/appService";
import { appServiceApi } from "services/appService";
import { selectIsLoggedIn } from "features/loginToken/loginTokenSlice";
import { getStore, RootState } from "app/store";
import { QueryState } from "services/util";
import { AppToast } from "app/types";

interface SettingsScreenData {
    venueData?: VenueData;
    venueId?: number;
    online: boolean;
    isLoggedIn: boolean;
    tasksQueryState?: QueryState;
    staffQueryState?: QueryState;
    suppliersQueryState?: QueryState;
    debugReportQueryState?: QueryState;
    reloadingPlan: boolean;
    reloadingTasks: boolean;
    reloadingStaff: boolean;
    reloadingSuppliers: boolean;
    submittingDebugReport: boolean;
    reloadPlan: () => void;
    reloadTasks: () => void;
    reloadStaff: () => void;
    reloadSuppliers: () => void;
    submitDebugReport: () => void;
    confirmFactoryReset: () => void;
}

function getToast(kind: string): AppToast {
    return {
        id: `reload_${kind}_` + new Date().getTime(),
        title: `Your ${kind} have been reloaded.`,
        created: new Date().toISOString(),
        type: "success",
        permanent: false,
    };
}

export default function useSettingsScreen(): SettingsScreenData {
    const venueData = useAppSelector(selectVenueData);
    const venueId = useAppSelector(selectActiveVenueId);
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const online = useAppSelector(selectOnline);
    const reloadingPlan = useAppSelector(selectReloadingPlan);
    const reloadingTasks = useAppSelector(selectReloadingTasks);
    const reloadingStaff = useAppSelector(selectReloadingStaff);
    const reloadingSuppliers = useAppSelector(selectReloadingSuppliers);
    const submittingDebugReport = useAppSelector(selectDebugReport);
    const dispatch = useAppDispatch();

    let tasksQueryState: QueryState | undefined;
    let staffQueryState: QueryState | undefined;
    let suppliersQueryState: QueryState | undefined;
    let debugReportQueryState: QueryState | undefined;
    if (isLoggedIn) {
        const state = getStore().getState();

        const tasksState = selectTasksDataQueryState(state);
        if (tasksState) tasksQueryState = tasksState;

        const staffState = selectStaffQueryState(state);
        if (staffState) staffQueryState = staffState;

        const supplierState = selectSuppliersQueryState(state);
        if (supplierState) suppliersQueryState = supplierState;

        const debugReportState = selectDebugReportQueryState(state);
        if (debugReportState) debugReportQueryState = debugReportState;
    }

    const reloadPlan = useCallback(() => {
        dispatch(setReloadingPlan(true));
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }, [dispatch]);

    const reloadTasks = useCallback(async () => {
        if (isLoggedIn && venueId) {
            dispatch(setReloadingTasks(true));
            await dispatch(
                appServiceApi.endpoints.getTasks.initiate(venueId, {
                    forceRefetch: true,
                })
            );
            dispatch(setReloadingTasks(false));
            dispatch(addAppToast(getToast("tasks")));
        }
    }, [dispatch, isLoggedIn, venueId]);

    const reloadStaff = useCallback(async () => {
        if (isLoggedIn && venueId) {
            dispatch(setReloadingStaff(true));
            await dispatch(
                appServiceApi.endpoints.getStaff.initiate(venueId, {
                    forceRefetch: true,
                })
            );
            dispatch(setReloadingStaff(false));
            dispatch(addAppToast(getToast("staff")));
        }
    }, [dispatch, isLoggedIn, venueId]);

    const reloadSuppliers = useCallback(async () => {
        if (isLoggedIn && venueId) {
            dispatch(setReloadingSuppliers(true));
            await dispatch(
                appServiceApi.endpoints.getSuppliers.initiate(venueId, {
                    forceRefetch: true,
                })
            );
            dispatch(setReloadingSuppliers(false));
            dispatch(addAppToast(getToast("suppliers")));
        }
    }, [dispatch, isLoggedIn, venueId]);

    const submitDebugReport = useCallback(async () => {
        if (isLoggedIn && venueId) {
            dispatch(setDebugReport(true));
            await dispatch(
                appServiceApi.endpoints.submitDebugReport.initiate(venueId, {
                    forceRefetch: true,
                })
            );
            dispatch(setDebugReport(false));
            dispatch(addAppToast(getToast("debug")));
        }
    }, [dispatch, isLoggedIn, venueId]);

    const confirmFactoryReset = useCallback(() => {
        dispatch(setShowResetConfirmation(true));
    }, [dispatch]);

    return {
        online,
        venueData,
        venueId,
        isLoggedIn,
        tasksQueryState,
        staffQueryState,
        suppliersQueryState,
        debugReportQueryState,
        reloadingPlan,
        reloadingTasks,
        reloadingStaff,
        reloadingSuppliers,
        submittingDebugReport,
        reloadPlan,
        reloadTasks,
        reloadStaff,
        reloadSuppliers,
        submitDebugReport,
        confirmFactoryReset,
    };
}
