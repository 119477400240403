import type { AppStartListening } from "app/listeners";
import { Dictionary } from "@reduxjs/toolkit";
import { envApi } from "services/env";
import type { Environment, TaskViewData, CategoryView } from "./types";
import { setEnvironment, setTaskViewData, setOnline } from "./envSlice";
import {
    selectActiveScreen,
    setActiveScreen,
    DEFAULT_SCREEN,
} from "app/appSlice";

export const startEnvListening = (startListening: AppStartListening) => {
    startListening({
        matcher: envApi.endpoints.getEnv.matchFulfilled,
        effect: (action, listenerApi) => {
            let env = action.payload as Environment;
            const thermometerCalibrationCategoryIds =
                env?.thermometerCalibrationCategoryIds || [];
            env = {
                ...env,
                thermometerCalibrationCategoryIds,
            };
            listenerApi.dispatch(setEnvironment(env));
            const activeScreen = selectActiveScreen(listenerApi.getState());
            if (activeScreen === "init")
                listenerApi.dispatch(setActiveScreen(DEFAULT_SCREEN));
        },
    });
};

export const startTaskViewDataListening = (
    startListening: AppStartListening
) => {
    startListening({
        matcher: envApi.endpoints.getTaskViewData.matchFulfilled,
        effect: (action, listenerApi) => {
            const taskViewData = action.payload as TaskViewData;
            const globalCalculations = taskViewData.calculations || {};
            const globalPredicates = taskViewData.predicates || {};
            let tasksData = {
                categoryViews: {} as Dictionary<CategoryView>,
            };
            for (let categoryId in taskViewData.categoryViews) {
                let categoryData = taskViewData.categoryViews[
                    categoryId
                ] as CategoryView;
                let taskView = {
                    ...categoryData.taskView,
                    calculations: {
                        ...categoryData.taskView.calculations,
                        ...globalCalculations,
                    },
                    predicates: {
                        ...categoryData.taskView.predicates,
                        ...globalPredicates,
                    },
                };
                tasksData.categoryViews[categoryId] = {
                    ...categoryData,
                    taskView,
                };
            }

            listenerApi.dispatch(setTaskViewData(tasksData));
        },
    });
};

// We update env reguarly to monitor if we are online.
// We don't use window.navigator.onLine as it only means
// we are connected to a local network, not internet.
export const startOfflineListening = (startListening: AppStartListening) => {
    startListening({
        matcher: envApi.endpoints.getEnv.matchRejected,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(setOnline(false));
        },
    });
    startListening({
        matcher: envApi.endpoints.getEnv.matchFulfilled,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(setOnline(true));
        },
    });
};

const listeners = [
    startEnvListening,
    startTaskViewDataListening,
    startOfflineListening,
];
export default listeners;
