import React, { ReactNode } from "react";
import classnames from "classnames";
import { useIdleTimer } from "react-idle-timer";
import { Login } from "./features/loginToken/Login";
import { useApp } from "./app/useApp";
import styles from "./App.module.scss";
import Loader from "app/Loader";
import InitScreen from "app/InitScreen";
import Header from "features/header/Header";
import DiaryScreen from "features/diary/DiaryScreen";
import SettingsScreen from "features/settings/SettingsScreen";
import TasksScreen from "features/tasks/TasksScreen";
import StaffSelector from "features/staff/StaffSelector";
import Confirmation from "app/Confirmation";
import DiaryQueue from "features/diary/DiaryQueue";
import "app/customBootstrap.scss";
import { Back, Success, Warning } from "icons/icons";
import Error from "app/Error";

import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

function App() {
    const {
        loggedIn,
        loginError,
        venueData,
        venueQueryState,
        activeScreen,
        showUserSelect,
        showSignoutConfirmation,
        showResetConfirmation,
        showDiaryQueue,
        hasSetAllData,
        toasts,
        error,
        onIdle,
        onCancelShowSignoutConfirmation,
        onSignout,
        onCancelShowResetConfirmation,
        onReset,
        dimissToast,
        onDismissError,
        onCloseDiaryQueue,
    } = useApp();

    useIdleTimer({ onIdle, timeout: 10000 });

    let displayLoginError = loginError;
    let activeScreenComponent;
    if (activeScreen === "init") {
        activeScreenComponent = <InitScreen />;
    } else if (loggedIn && (!venueData || !hasSetAllData)) {
        if (venueQueryState?.isLoading || !hasSetAllData) {
            activeScreenComponent = <Loader>Loading venue data...</Loader>;
        } else {
            if (!displayLoginError)
                displayLoginError = "Error loading venue data";
            if (venueQueryState?.error) {
                displayLoginError = `${displayLoginError}: ${venueQueryState.error}`;
            }
            console.warn("%o: %o", displayLoginError, venueQueryState);
        }
    } else if (loggedIn && venueData) {
        if (activeScreen === "tasks") {
            activeScreenComponent = (
                <div>
                    <TasksScreen />
                </div>
            );
        } else if (activeScreen === "settings") {
            activeScreenComponent = <SettingsScreen />;
        } else if (activeScreen === "diary") {
            activeScreenComponent = <DiaryScreen />;
        } else {
            displayLoginError = `Unknown screen: ${activeScreen}`;
        }
    }
    if (!activeScreenComponent) {
        activeScreenComponent = <Login loginError={displayLoginError} />;
    }

    let staffSelector;
    if (showUserSelect) {
        staffSelector = <StaffSelector />;
    }

    let diaryQueue;
    if (showDiaryQueue) {
        diaryQueue = <DiaryQueue dismiss={onCloseDiaryQueue} />;
    }

    let signoutConfirmation;
    if (showSignoutConfirmation) {
        signoutConfirmation = (
            <Confirmation
                title="Sign out of venue?"
                confirmTitle="Sign out"
                onCancel={onCancelShowSignoutConfirmation}
                onConfirm={onSignout}
            ></Confirmation>
        );
    }

    let resetConfirmation;
    if (showResetConfirmation) {
        resetConfirmation = (
            <Confirmation
                title="Reset all data?"
                confirmTitle="Reset"
                onCancel={onCancelShowResetConfirmation}
                onConfirm={onReset}
            ></Confirmation>
        );
    }

    let toastComponents;
    if (toasts) {
        toastComponents = toasts.map((toast): ReactNode => {
            let toastClasses = ["p-2", styles.toast];
            let headerClasses = ["border-bottom-0", "bg-transparent"];
            const autohide = !toast.permanent;
            let icon;
            if (toast.type === "success") {
                toastClasses.push(styles.success);
                headerClasses.push(styles.successText);
                icon = <Success />;
            } else if (toast.type === "warning") {
                toastClasses.push(styles.warning);
                headerClasses.push(styles.warningText);
                icon = <Warning />;
            }
            const delay = toast.delay || 3000;

            return (
                <Toast
                    onClose={() => dimissToast(toast)}
                    delay={delay}
                    autohide={autohide}
                    key={toast.id}
                    className={classnames(...toastClasses)}
                >
                    <Toast.Header
                        className={classnames(...headerClasses)}
                        closeButton={toast.permanent}
                    >
                        {icon}
                        <strong className="me-auto">{toast.title}</strong>
                    </Toast.Header>
                    {toast.content && <Toast.Body>{toast.content}</Toast.Body>}
                </Toast>
            );
        });
    }

    let appClass = loggedIn && !loginError ? styles.App : styles.AppLogin;

    return (
        <div className={appClass}>
            {toastComponents && (
                <div className={styles.toastsContainer}>
                    <ToastContainer className="p-0 w-100" position="top-end">
                        {toastComponents}
                    </ToastContainer>
                </div>
            )}
            {loggedIn && !loginError && <Header />}
            <div className={styles.activeScreen}>{activeScreenComponent}</div>
            {staffSelector}
            {signoutConfirmation}
            {resetConfirmation}
            {diaryQueue}
            {error && <Error onConfirm={onDismissError}>{error}</Error>}
        </div>
    );
}

export default App;
