import { Staff } from "features/staff/types";
import { selectStaffWithSignoff } from "features/staff/staffSlice";
import {
    selectActiveUser,
    setActiveUser,
    setHasBeenIdle,
    setShowUserSelect,
} from "app/appSlice";
import { appDispatch, appSelect } from "app/util";

export interface StaffSelectorData {
    staff: Staff[];
    activeUserId?: number;
    activeUser?: Staff;
    selectUserId: (userId: number | undefined) => void;
    dismiss: () => void;
}

const dismiss = () => {
    appDispatch(setShowUserSelect(false));
};

const selectUserId = (userId: number | undefined) => {
    appDispatch(setActiveUser(userId));
    appDispatch(setHasBeenIdle(false));
    dismiss();
};

export const useStaffSelector = (): StaffSelectorData => {
    const staff = appSelect(selectStaffWithSignoff);
    const activeUserId = appSelect(selectActiveUser);
    const activeUser =
        staff.filter((staffMember) => staffMember.id === activeUserId)[0] ||
        null;

    return {
        staff,
        activeUserId,
        activeUser,
        selectUserId,
        dismiss,
    };
};

export default useStaffSelector;
