import { Staff } from "features/staff/types";
import { selectStaff } from "features/staff/staffSlice";
import {
    selectActiveUser,
    setShowUserSelect,
    selectHasBeenIdle,
} from "app/appSlice";
import { appDispatch, appSelect } from "app/util";

export interface ActiveStaffData {
    staff: Staff[];
    activeUserId?: number;
    activeUser?: Staff;
    hasBeenIdle: boolean;
    selectUser: () => void;
}

const selectUser = () => {
    appDispatch(setShowUserSelect(true));
};

export const useActiveStaff = (): ActiveStaffData => {
    const staff = appSelect(selectStaff);
    const activeUserId = appSelect(selectActiveUser);
    const activeUser =
        staff.filter((staffMember) => staffMember.id === activeUserId)[0] ||
        null;
    const hasBeenIdle = appSelect(selectHasBeenIdle);

    return {
        staff,
        activeUserId,
        activeUser,
        hasBeenIdle,
        selectUser,
    };
};

export default useActiveStaff;
