import {
    createApi,
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { selectEnv } from "features/environment/envSlice";

const authBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const env = selectEnv(api.getState() as RootState);

    // gracefully handle scenarios where data to generate the URL is missing
    if (!env || !("authServiceBaseUrl" in env)) {
        return {
            error: {
                status: 400,
                statusText: "Bad Request",
                data: "No environment data received",
            },
        };
    }

    const authServiceBaseUrl = env.authServiceBaseUrl;

    if (args instanceof Object && args["method"] === "POST") {
        let body = args["body"];
        if ("grant_type" in body) {
            body["client_id"] = env.authClientId;
            body["client_secret"] = env.authClientSecret;
        }
    }

    return fetchBaseQuery({
        baseUrl: authServiceBaseUrl,
    })(args, api, extraOptions);
};

export interface Credential {
    username: string;
    password: string;
}

export interface TokenResponse {
    token_type: string;
    expires_in: number;
    access_token: string;
    refresh_token: string;
}

export interface BearerToken {
    token: string;
    expiresIn: number;
    refreshToken: string;
}

export const authServiceApi = createApi({
    reducerPath: "authServiceApi",
    baseQuery: authBaseQuery,
    endpoints: (builder) => ({
        getBearerToken: builder.query<BearerToken, Credential>({
            query: (credential) => {
                return {
                    url: "oauth/token",
                    method: "POST",
                    body: {
                        grant_type: "password",
                        username: credential.username,
                        password: credential.password,
                    },
                };
            },
            transformResponse: (response, meta) => {
                let tokenResponse = response as TokenResponse;

                return {
                    token: tokenResponse.access_token,
                    expiresIn: tokenResponse.expires_in,
                    refreshToken: tokenResponse.refresh_token,
                };
            },
        }),
    }),
});
