import type { AppStartListening } from "app/listeners";
import {
    appSlice,
    addAppToast,
    selectAppToasts,
    removeAppToast,
} from "app/appSlice";
import { appServiceApi, getServerError } from "services/appService";
import {
    loginTokenSlice,
    setLoginError,
    setUserVenues,
    setBearerToken,
    selectFernetToken,
    setTokenSource,
    setShowLogin,
} from "./loginTokenSlice";
import { parseQrCodeData } from "./useLoginToken";
import { setFernetToken, selectTokenSource } from "./loginTokenSlice";
import { authServiceApi } from "services/authService";
import { resetState, setServerError, selectServerErrors } from "app/appSlice";

export const startUrlPathListening = (startListening: AppStartListening) => {
    startListening({
        actionCreator: appSlice.actions.setUrlPath,
        effect: (action, listenerApi) => {
            const [fernetToken, tokenSource] =
                parseQrCodeData(action.payload) || [];
            if (fernetToken) {
                listenerApi.dispatch(resetState());
                listenerApi.dispatch(setFernetToken(fernetToken));
                listenerApi.dispatch(setTokenSource(tokenSource));
                window.history.replaceState(null, "Chomp", "/");
            }
        },
    });
};

export const startSetFernetTokenListening = (
    startListening: AppStartListening
) => {
    startListening({
        actionCreator: loginTokenSlice.actions.setFernetToken,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(setShowLogin(false));
            if (action.payload) {
                listenerApi.dispatch(
                    appServiceApi.endpoints.getVenues.initiate(void 0, {
                        forceRefetch: true,
                    })
                );
            }
        },
    });
};

export const startGetBearerTokenListening = (
    startListening: AppStartListening
) => {
    startListening({
        matcher: authServiceApi.endpoints.getBearerToken.matchFulfilled,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(setBearerToken(action.payload));
            if (action.payload) {
                listenerApi.dispatch(
                    appServiceApi.endpoints.getVenues.initiate(void 0, {
                        forceRefetch: true,
                    })
                );
            }
        },
    });

    startListening({
        matcher: authServiceApi.endpoints.getBearerToken.matchRejected,
        effect: (action, listenerApi) => {
            if (!action.payload) return;

            const data = action.payload?.data;
            let error = "There was an error logging in";
            if (data instanceof Object && "error" in data) {
                switch (data["error"]) {
                    case "invalid_grant":
                        error = "Invalid email or password";
                        break;
                }
            }
            listenerApi.dispatch(setLoginError(error));
            listenerApi.dispatch(setBearerToken());
            listenerApi.dispatch(setUserVenues());
        },
    });
};

export const startUserVenuesListening = (startListening: AppStartListening) => {
    startListening({
        matcher: appServiceApi.endpoints.getVenues.matchFulfilled,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(setUserVenues(action.payload));
            if (action.payload.length === 0) {
                listenerApi.dispatch(
                    setLoginError("No venues available for this user")
                );
            }
            listenerApi.dispatch(setServerError(["venue", void 0]));
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.getVenues.matchRejected,
        effect: (action, listenerApi) => {
            if (!action.payload) return;

            const data = action.payload?.data;
            let error = "Unable to load user venues";
            if (data instanceof Object && "error" in data) {
                const tokenSource = selectTokenSource(listenerApi.getState());
                switch (data["error"]) {
                    case "invalid_header":
                        error =
                            tokenSource === "migrate"
                                ? "Invalid migration code."
                                : "Invalid QR code.";
                        break;
                }
            }

            const serverError = getServerError(action);
            listenerApi.dispatch(setServerError(["venue", serverError]));
            listenerApi.dispatch(setLoginError(error));
            listenerApi.dispatch(setBearerToken());
            listenerApi.dispatch(setFernetToken());
        },
    });
};

let _shownServerErrorToast = false;
export const startServerErrorListening = (
    startListening: AppStartListening
) => {
    startListening({
        actionCreator: appSlice.actions.setServerError,
        effect: (action, listenerApi) => {
            const [_, error] = action.payload;
            const serverErrors = selectServerErrors(listenerApi.getState());
            const unauthorisedErrors = serverErrors
                ? Object.values(serverErrors).filter(
                      (error) =>
                          error.httpCode === 401 || error.httpCode === 403
                  )
                : [];
            const toasts = selectAppToasts(listenerApi.getState()) || [];
            let loggedOutToasts = toasts.filter((toast) =>
                toast.id.startsWith("logged_out_")
            );
            // We only want to show the toast once - the first time a 401/403
            // error is returned.
            // serverErrors will have the current error already added hence
            // check for length === 1
            if (
                (error?.httpCode === 401 || error?.httpCode === 403) &&
                loggedOutToasts.length === 0 &&
                !_shownServerErrorToast
            ) {
                // Only show the logged-out toast if they were logged in
                const token = selectFernetToken(listenerApi.getState());
                if (token) {
                    if (loggedOutToasts.length === 0) {
                        listenerApi.dispatch(
                            addAppToast({
                                id: `logged_out_` + new Date().getTime(),
                                title: `You have been automatically logged out. Please login again.`,
                                created: new Date().toISOString(),
                                type: "warning",
                                permanent: true,
                            })
                        );
                        _shownServerErrorToast = true;
                    }
                }
            } else if (
                unauthorisedErrors.length === 0 &&
                loggedOutToasts.length > 0
            ) {
                // If there's no 401/403 errors, hide any logged-out toasts
                // as the user will have been logged-in again
                loggedOutToasts.forEach((toast) => {
                    listenerApi.dispatch(removeAppToast(toast));
                });
            }

            if (unauthorisedErrors.length === 0) _shownServerErrorToast = false;
        },
    });
};

const listeners = [
    startUrlPathListening,
    startUserVenuesListening,
    startSetFernetTokenListening,
    startGetBearerTokenListening,
    startServerErrorListening,
];
export default listeners;
