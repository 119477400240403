import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { appSelect, appSelectArg } from "app/util";
import { selectActiveTasksScreen, setActiveTasksScreen } from "app/appSlice";
import type { TasksScreen } from "app/types";
import { getStore, RootState } from "app/store";
import { selectTasks, selectCategoryEntities } from "./tasksSlice";
import { selectCategoryView } from "features/environment/envSlice";
import { selectVenueData } from "features/venue/venueSlice";
import type { CategoryView } from "features/environment/types";
import type { TaskObjectsFunction } from "./functions";
import { TaskData, TaskStatus, TaskUrgencies } from "./types";
import {
    groupData,
    taskDataMemo,
    computeTypeTasks,
    computeGroups,
    computeUrgencyTasks,
    getDueTasks,
    sortCategoryGroups,
} from "./functions";
import type { QueryState } from "services/util";
import { isClosed } from "features/venue/util";
import { selectTasksDataQueryState } from "services/appService";

export interface TasksScreenData {
    tasksQueryState?: QueryState;
    urgentGroups: TaskData[][];
    dueGroups: TaskData[][];
    frequentGroups: TaskData[][];
    eventGroups: TaskData[][];
    upToDate: boolean;
    activeTasksScreen: TasksScreen;
    activeCategoryView?: CategoryView;
    activeTask?: TaskData;
    venueIsClosed: boolean;
    getTaskObjects: TaskObjectsFunction;
    handleGroupClick: (
        group: TaskData[],
        status: TaskStatus,
        getTaskObjects: TaskObjectsFunction
    ) => void;
    dismissModal: () => void;
}
export function handleGroupClick(
    group: TaskData[],
    status: TaskStatus,
    getTaskObjects: TaskObjectsFunction
) {
    const category = groupData(group, getTaskObjects);
    let categoryView;
    if (category) {
        categoryView = appSelectArg(selectCategoryView, category.id);
    }
    let taskId: number | undefined = void 0;
    let categoryId: number | undefined = void 0;
    if (group.length === 1 && !categoryView?.alwaysGroup) {
        taskId = group[0].id;
    } else {
        if (category) {
            categoryId = category.id;
        }
    }

    let taskScreen: TasksScreen = {
        taskId,
        categoryId,
        status,
    };

    // Hides popover if this task group is active
    const activeTaskGroup = appSelect(selectActiveTasksScreen);
    if (
        activeTaskGroup.taskId === taskScreen.taskId &&
        activeTaskGroup.categoryId === taskScreen.categoryId &&
        activeTaskGroup.status === taskScreen.status
    ) {
        taskScreen = {};
    }

    getStore().dispatch(setActiveTasksScreen(taskScreen));
}

function removeProvenTasks(tasks: TaskData[]): TaskData[] {
    return tasks.filter((task) => task.proven_count < 3);
}

export const useTasksScreen = (): TasksScreenData => {
    const tasks = useAppSelector(selectTasks);
    const categories = useAppSelector(selectCategoryEntities);
    const getTaskObjects = taskDataMemo(categories);
    const activeTasksScreen = useAppSelector(selectActiveTasksScreen);
    const dispatch = useAppDispatch();

    let activeCategoryView;
    if (activeTasksScreen.categoryId) {
        activeCategoryView = appSelectArg(
            selectCategoryView,
            activeTasksScreen.categoryId
        );
    }

    const tasksQueryState = useAppSelector((state: RootState) =>
        selectTasksDataQueryState(state)
    );

    const urgencyTasks = computeUrgencyTasks(tasks);
    const urgentTasks = urgencyTasks[TaskUrgencies.URGENT] || [];
    const dueTasks = urgencyTasks[TaskUrgencies.DUE] || [];
    // Frequent/Events with a proven count of 3-or-above should not be shown
    const frequentTasks = removeProvenTasks(
        urgencyTasks[TaskUrgencies.FREQUENT] || []
    );
    const otherEventTasks = removeProvenTasks(
        computeTypeTasks(urgencyTasks[TaskUrgencies.OTHER] || [], "event")
    );

    const urgentGroups = computeGroups(urgentTasks, getTaskObjects).sort(
        (groupA, groupB) => {
            return sortCategoryGroups(groupA, groupB, getTaskObjects);
        }
    );

    const dueGroups = computeGroups(dueTasks, getTaskObjects).sort(
        (groupA, groupB) => {
            return sortCategoryGroups(groupA, groupB, getTaskObjects);
        }
    );

    const frequentGroups = computeGroups(frequentTasks, getTaskObjects).sort(
        (groupA, groupB) => {
            return sortCategoryGroups(groupA, groupB, getTaskObjects);
        }
    );

    const eventGroups = computeGroups(otherEventTasks, getTaskObjects).sort(
        (groupA, groupB) => {
            return sortCategoryGroups(groupA, groupB, getTaskObjects);
        }
    );

    const dueScheduledTasks = getDueTasks(
        urgentTasks.concat(dueTasks),
        getTaskObjects
    );

    const upToDate = dueScheduledTasks.length === 0;

    let venueIsClosed = false;
    const venueData = useAppSelector(selectVenueData);
    if (venueData) {
        venueIsClosed = isClosed(venueData.closedDays);
    }

    const dismissModal = useCallback(() => {
        dispatch(setActiveTasksScreen({}));
    }, [dispatch]);

    return {
        tasksQueryState,
        urgentGroups,
        dueGroups,
        frequentGroups,
        eventGroups,
        upToDate,
        activeTasksScreen,
        activeCategoryView,
        venueIsClosed,
        dismissModal,
        getTaskObjects,
        handleGroupClick,
    };
};
