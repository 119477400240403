import React, { SyntheticEvent } from "react";
import { loginType } from "./loginTokenSlice";
import useLoginToken from "./useLoginToken";
import type { FormData } from "./useLoginToken";
import styles from "./Login.module.scss";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import loaderStyles from "app/Loader.module.css";
import LoginError from "./LoginError";
import QRCodeScanner from "./QRCodeScanner";
import LoginButtons from "./LoginButtons";

interface Props {
    loginError?: string;
}

export function Login(props: Props) {
    const {
        cameraEnabled,
        loginType,
        loginError,
        passwordLoginState,
        getVenuesQueryState,
        showEmailLogin,
        tokenSource,
        showLogin,
        register,
        handleSubmit,
        loginWithPassword,
        processQrResult,
        selectedLoginType,
    } = useLoginToken();

    const onSubmit = (data: FormData) => {
        loginWithPassword();
    };

    function onSelect(
        eventKey: string | null,
        event: SyntheticEvent<unknown, Event>
    ) {
        if (eventKey) {
            selectedLoginType(eventKey as loginType);
        }
    }

    let loginComponent;
    let loginButtons;
    if (showLogin) {
        if (loginType === "qrCode") {
            loginComponent = (
                <>
                    {!getVenuesQueryState?.isLoading && (
                        <QRCodeScanner
                            processQrResult={processQrResult}
                            getVenuesQueryState={getVenuesQueryState}
                        />
                    )}
                    {getVenuesQueryState?.isLoading && (
                        <div className={styles.loginLoader}>
                            <div className={loaderStyles.Loader}>
                                <img
                                    src="/ChompSpinner.svg"
                                    alt="Logging in..."
                                />
                            </div>
                        </div>
                    )}
                </>
            );
        } else {
            loginComponent = (
                <div className={styles.venueKeyForm}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                defaultValue={""}
                                {...register("username")}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                defaultValue={""}
                                {...register("password")}
                            />
                        </Form.Group>
                        {!passwordLoginState?.isLoading && (
                            <Button type="submit" value="signin">
                                Sign In
                            </Button>
                        )}
                        {passwordLoginState?.isLoading && (
                            <div className={styles.loginLoader}>
                                <div className={loaderStyles.Loader}>
                                    <img
                                        src="/ChompSpinner.svg"
                                        alt="Logging in..."
                                    />
                                </div>
                            </div>
                        )}
                    </Form>
                </div>
            );
        }
    } else {
        loginButtons = <LoginButtons />;
    }

    let loginErrorComponent;
    if ((loginError || props.loginError) && !getVenuesQueryState?.isLoading) {
        loginErrorComponent = (
            <LoginError
                loginError={loginError || props.loginError}
                tokenSource={tokenSource}
            />
        );
    }

    let loginScreen;
    if (getVenuesQueryState?.isLoading) {
        loginScreen = (
            <>
                <h2>
                    <img
                        src="/Chomp-Logo.png"
                        alt="Chomp"
                        className={styles.logo}
                    />
                </h2>
                <div className={styles.loginLoader}>
                    <div className={loaderStyles.Loader}>
                        <img src="/ChompSpinner.svg" alt="Logging in..." />
                    </div>
                </div>
            </>
        );
    } else {
        loginScreen = (
            <>
                {/* {showLogin && (
                    <>
                        <h2>
                            Sign in to{" "}
                            <img
                                src="/Chomp-Logo.png"
                                alt="Chomp"
                                className={styles.logo}
                            />
                        </h2>

                        <Nav
                            activeKey={loginType}
                            variant="pills"
                            onSelect={onSelect}
                        >
                            {cameraEnabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="qrCode">
                                        QR Code
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                            {showEmailLogin && (
                                <Nav.Item>
                                    <Nav.Link eventKey="password">
                                        Email
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                        </Nav>
                    </>
                )} */}

                {/* {!showLogin && (
                    <h2>
                        <img
                            src="/Chomp-Logo-simplified.png"
                            alt="Chomp"
                            className={styles.logo}
                        />
                    </h2>
                )} */}

                <h2>
                    <img
                        src="/Chomp-Logo-simplified.png"
                        alt="Chomp"
                        className={styles.logo}
                    />
                </h2>

                {loginComponent}
                {loginErrorComponent}
                {loginButtons}
            </>
        );
    }

    return <div className="loginScreen">{loginScreen}</div>;
}
