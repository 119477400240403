import { Metric } from "web-vitals";
import ReactGA from "react-ga4";

ReactGA.initialize("G-T2R6MXEPNH");

function reportHandler(metric: Metric) {
    ReactGA.event({
        category: "Web Vitals",
        action: metric.name,
        label: metric.id, // optional
        value: metric.value, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
    });
}

export default reportHandler;

export const reportException = (location: string, error: Error) => {
    console.log("App Reporting Exception: %o", error);
    const message = error.message.replace(/TypeError\: Cannot Read Properties of Undefined/g,'');
    ReactGA.event({
        category: "Exceptions",
        action: `${location} Exception`,
        label: message, // optional
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
    });
    // GA 4 event format
    const stack = error.stack ?? '';
    ReactGA.event(
        "exceptions",
        {
            location: `${location}`,
            message: error.message,
            page_location: error.stack
        }
    );
};
