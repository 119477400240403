import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { getStore, getPersistor } from "app/store";
import App from "./App";
import reportWebVitals from "reportWebVitals";
import reportHandler from "./reportHandler";

import "./index.css";
import { PersistGate } from "redux-persist/integration/react";
import { ErrorBoundary } from "react-error-boundary";
import { reportException } from "./reportHandler";
import Error from "app/Error";

//import { hotjar } from "react-hotjar";

//import LogRocket from 'logrocket';
//import setupLogRocketReact from 'logrocket-react';

//LogRocket.init('n4vcpt/chomp-chef');
//setupLogRocketReact(LogRocket);

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <ErrorBoundary
        fallbackRender={({ error }) => {
            reportException("App", error);
            return (
                <Error>
                    <p><b>Oh no! Something went wrong loading your app!</b></p>
                    <p>
                        <i>{error.message}</i>
                    </p>
                </Error>
            );
        }}
    >
        <React.StrictMode>
            <Provider store={getStore()}>
                <PersistGate loading={null} persistor={getPersistor()}>
                    <App />
                </PersistGate>
            </Provider>
        </React.StrictMode>
    </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(reportHandler);

//hotjar.initialize(3618240, 6);
