import React from "react";
import styles from "./Header.module.scss";

interface Props {
    children: string | React.ReactElement;
    icon?: JSX.Element; // SVG icon
    onClick: () => void;
}

export default function HeaderButton(props: Props) {
    return (
        <div className={styles.headerButton} onClick={props.onClick}>
            {props.icon}
            {props.children}
        </div>
    );
}
