import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Environment, CategoryView, TaskViewData } from "./types";
import { timestamp } from "types";

export interface EnvironmentState extends Environment, TaskViewData {
    online: boolean;
    thermometerCalibrationCategoryIds: number[];
    uniqueId?: string;
    offlineStart?: timestamp;
}

const initialState: EnvironmentState = {
    online: true,
    thermometerCalibrationCategoryIds: [],
};

export const environmentSlice = createSlice({
    name: "environment",
    initialState,
    reducers: {
        setEnvironment: (
            state: EnvironmentState,
            action: PayloadAction<Environment>
        ) => {
            return { ...state, ...action.payload };
        },
        setTaskViewData: (
            state: EnvironmentState,
            action: PayloadAction<TaskViewData>
        ) => {
            return { ...state, ...action.payload };
        },
        setUniqueId: (
            state: EnvironmentState,
            action: PayloadAction<string>
        ) => {
            state.uniqueId = action.payload;
        },
        setOnline: (
            state: EnvironmentState,
            action: PayloadAction<boolean>
        ) => {
            state.online = action.payload;
            if (action.payload) {
                state.offlineStart = void 0;
            } else if (!state.offlineStart) {
                state.offlineStart = Date.now();
            }
        },
    },
});

export const { setEnvironment, setTaskViewData, setUniqueId, setOnline } =
    environmentSlice.actions;

export const selectEnv = (state: RootState): EnvironmentState =>
    state.environment;
export const selectUniqueId = (state: RootState) => state.environment.uniqueId;
export const selectCategoryView = (
    state: RootState,
    categoryId: number
): CategoryView | undefined => {
    const env = selectEnv(state);
    if (env.categoryViews) {
        if (categoryId in env.categoryViews) {
            return env.categoryViews[categoryId];
        }
    }

    return void 0;
};
export const selectOnline = (state: RootState): boolean =>
    state.environment.online;
export const selectOfflineInterval = (state: RootState): number | undefined => {
    let interval;
    if (state.environment.offlineStart) {
        interval = (Date.now() - state.environment.offlineStart) / 1000;
    }

    return interval;
};
export const selectOfflineStart = (state: RootState): Date | undefined => {
    let start;
    if (state.environment.offlineStart) {
        start = new Date(state.environment.offlineStart);
    }

    return start;
};
export const selectThermometerCalibrationCategoryIds = (
    state: RootState
): number[] => {
    return state.environment.thermometerCalibrationCategoryIds;
};
export const selectFetchTemplateIdDiaryEntries = (
    state: RootState
): number[] => {
    return state.environment.fetchTemplateIdDiaryEntries || [];
};
export const selectShowEmailLogin = (state: RootState): boolean => {
    return !!state.environment.showEmailLogin;
};
export const selectBranchName = (state: RootState): string | undefined => {
    return state.environment.branchName;
};

export default environmentSlice.reducer;
