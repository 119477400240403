import { selectDiaryEntryQueue, selectDiaryFilesQueue } from "app/appSlice";
import { selectTasks } from "features/tasks/tasksSlice";
import { getStore } from "app/store";
import { getLastPerformedMessage } from "features/tasks/functions";

export type DiaryQueueEntry = {
    name: string;
    time: string;
    id: string;
    files: number;
};

export interface DiaryQueueData {
    diaryQueueEntries: DiaryQueueEntry[];
}

export default function useDiaryQueue(): DiaryQueueData {
    const state = getStore().getState();
    const tasks = selectTasks(state);
    const queuedDiaryEntries = selectDiaryEntryQueue(state);
    const queuedDiaryFiles = selectDiaryFilesQueue(state);
    let diaryQueueEntries: DiaryQueueEntry[] = [];
    queuedDiaryEntries.forEach((diaryEntry) => {
        const task = tasks.find((task) => task.id === diaryEntry.taskId);
        if (task) {
            let time = getLastPerformedMessage(diaryEntry.performed) || "";
            diaryQueueEntries.push({
                name: task.name,
                time,
                id: diaryEntry.uuid,
                files: 0,
            });
        }
    });

    queuedDiaryFiles.forEach((diaryFile) => {
        let diaryEntries = diaryQueueEntries.filter(
            (diary) => diary.id === diaryFile.diary_entry
        );
        let diaryEntry: DiaryQueueEntry;
        if (diaryEntries.length > 0) {
            diaryEntry = diaryEntries[0];
        } else {
            let name = "Unknown Task";
            const task = tasks.find((task) => task.id === diaryFile.taskId);
            if (task) {
                name = task.name;
            }
            let time = getLastPerformedMessage(diaryFile.performed) || "";
            diaryEntry = {
                name,
                time,
                id: diaryFile.diary_entry,
                files: 0,
            };
            diaryQueueEntries.push(diaryEntry);
        }

        diaryEntry.files += 1;
    });

    return {
        diaryQueueEntries,
    };
}
