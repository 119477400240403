import { appSelect, appDispatch } from "app/util";
import { selectShowEmailLogin } from "features/environment/envSlice";
import {
    setLoginType,
    setShowLogin,
    setLoginError,
    setTokenSource,
} from "./loginTokenSlice";
import { setServerErrors } from "app/appSlice";

interface LoginButtonsData {
    showPasswordLogin?: () => void;
    showQrCodeLogin?: () => void;
}

function setupState() {
    appDispatch(setShowLogin(true));
    appDispatch(setLoginError());
    appDispatch(setTokenSource());
    appDispatch(setServerErrors({}));
}

export default function useLoginButtons(): LoginButtonsData {
    let showPasswordLogin;
    if (appSelect(selectShowEmailLogin)) {
        showPasswordLogin = () => {
            appDispatch(setLoginType("password"));
            setupState();
        };
    }
    const showQrCodeLogin = () => {
        appDispatch(setLoginType("qrCode"));
        setupState();
    };

    return {
        showPasswordLogin,
        showQrCodeLogin,
    };
}
