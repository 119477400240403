import { appServiceApi } from "services/appService";
import { Supplier } from "./types";
import { setSuppliers } from "./suppliersSlice";
import { setServerError, selectActiveVenueId } from "app/appSlice";
import type { AppStartListening } from "app/listeners";

export const startSuppliersListening = (startListening: AppStartListening) => {
    startListening({
        matcher: appServiceApi.endpoints.getSuppliers.matchFulfilled,
        effect: (action, listenerApi) => {
            // Don't set data if not logged in to a venue or the
            // tasks venue is not the active venue
            const venueId = selectActiveVenueId(listenerApi.getState());
            if (!venueId || action.meta.arg.originalArgs !== venueId) return;

            const suppliers = action.payload as Supplier[];
            listenerApi.dispatch(setSuppliers(suppliers));
            listenerApi.dispatch(setServerError(["suppliers", void 0]));
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.getSuppliers.matchRejected,
        effect: (action, listenerApi) => {
            if (!action.payload) return;

            const status = action.payload?.status;
            const data = action.payload?.data;
            const datetime = new Date().toISOString();
            const error = {
                httpCode: Number(status),
                data,
                datetime,
            };
            listenerApi.dispatch(setServerError(["suppliers", error]));
        },
    });
};

const listeners = [startSuppliersListening];
export default listeners;
