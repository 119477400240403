import { useAppSelector, useAppDispatch } from "app/hooks";
import {
    pushActiveScreen,
    selectActiveScreen,
    selectActiveTasksScreen,
    setActiveTasksScreen,
    setShowSidebar,
    setShowSignoutConfirmation,
    selectDiaryEntryQueue,
    selectDiaryFilesQueue,
    setShowDiaryQueue,
} from "app/appSlice";
import { selectVenueData } from "features/venue/venueSlice";
import { selectOfflineStart } from "features/environment/envSlice";
import type { TasksScreen } from "app/types";
import type { screen } from "app/types";
import { getStore } from "app/store";
import type { DiaryEntry, DiaryFile } from "features/tasks/types";
import { useCallback } from "react";
import { getUniqueId, getBuildVersion } from "app/util";

export interface SidebarData {
    activeScreen: screen;
    activeTasksScreen: TasksScreen;
    offlineStart?: Date;
    queuedDiaryEntries: DiaryEntry[];
    queuedDiaryFiles: DiaryFile[];
    venueName?: string;
    deviceId: string;
    buildVersion: string;
    selectScreen: (screen: screen) => void;
    confirmSignout: () => void;
    resetTaskScreen: () => void;
    showDiaryQueue: () => void;
}

const selectScreen = (screen: screen) => {
    getStore().dispatch(pushActiveScreen(screen));
    getStore().dispatch(setShowSidebar(false));
};

const resetTaskScreen = () => {
    getStore().dispatch(setActiveTasksScreen({}));
};

export default function useSidebar(): SidebarData {
    const activeScreen = useAppSelector(selectActiveScreen);
    const activeTasksScreen = useAppSelector(selectActiveTasksScreen);
    const offlineStart = useAppSelector(selectOfflineStart);
    const queuedDiaryEntries = useAppSelector(selectDiaryEntryQueue);
    const queuedDiaryFiles = useAppSelector(selectDiaryFilesQueue);
    const venueData = useAppSelector(selectVenueData);
    const deviceId = getUniqueId();
    const buildVersion = getBuildVersion();
    const dispatch = useAppDispatch();

    let venueName;
    if (venueData) {
        venueName = venueData.venueName;
    }

    const confirmSignout = useCallback(() => {
        dispatch(setShowSignoutConfirmation(true));
    }, [dispatch]);

    const showDiaryQueue = useCallback(() => {
        dispatch(setShowDiaryQueue(true));
        dispatch(setShowSidebar(false));
    }, [dispatch]);

    return {
        activeScreen,
        activeTasksScreen,
        offlineStart,
        queuedDiaryEntries,
        queuedDiaryFiles,
        venueName,
        deviceId,
        buildVersion,
        confirmSignout,
        selectScreen,
        resetTaskScreen,
        showDiaryQueue,
    };
}
