import useLoginButtons from "./useLoginButtons";
import Button from "features/tasks/form/Button";
import styles from "./LoginButtons.module.scss";

export default function LoginButtons() {
    const { showPasswordLogin, showQrCodeLogin } = useLoginButtons();

    return (
        <div>
            <div className={styles.container}>
                {showPasswordLogin && (
                    <Button onClick={showPasswordLogin} className={styles.button}>
                        Login with password
                    </Button>
                )}
                {showQrCodeLogin && (
                    <Button onClick={showQrCodeLogin} className={styles.button}>
                        Login with QR Code
                    </Button>
                )}

                {!showPasswordLogin && !showQrCodeLogin && (
                    <div>No login options</div>
                )}
            </div>
            <div className={styles.container}>
                Don't have a QR Code?  Get one <a target="_blank" rel="noopener noreferrer" href="https://www.chompfoodsafety.com/info-hub/find-your-qr-code-login">here.</a>
            </div>
            <div className={styles.container}>
                Not using Chomp? You really should be. Find out what the fuss is all about.
                <br/>
                Go on, <a href="https://www.chompfoodsafety.com">click on me</a>, we won't bite!
            </div>
        </div>
    );
}
