import React, { useCallback } from "react";
import QrScanner from "qr-scanner";
import styles from "./QRCodeScanner.module.scss";
import { viewportHeightAvailable } from "app/util";
import { QueryState } from "services/util";

type ProcessResult = (result: QrScanner.ScanResult) => void;
type QRCommand = "start" | "stop";

class QrScannerController {
    _videoElement?: HTMLVideoElement;
    _status?: string;
    _nextCommand?: QRCommand;
    _qrScanner?: QrScanner;
    _processResult?: ProcessResult;

    set processResult(processResult: ProcessResult) {
        this._processResult = processResult;
    }

    set videoElement(element: HTMLVideoElement) {
        if (element === this._videoElement || !this._processResult) return;
        this._videoElement = element;
        this.setupListeners(element);
        this._qrScanner = this.getQRScanner(element, this._processResult);
    }

    set status(status: string) {
        this._status = status;
    }

    set nextCommand(command: QRCommand | undefined) {
        this._nextCommand = command;
    }

    setupListeners(element: HTMLVideoElement) {
        element.addEventListener("play", this.eventListener.bind(this));
        element.addEventListener("playing", this.eventListener.bind(this));
    }

    eventListener(e: Event) {
        this.status = e.type;
    }

    completedCommand() {
        if (this._nextCommand) {
            const command = this._nextCommand;
            this.nextCommand = void 0;
            this.processCommand(command);
        }
    }

    processCommand(command: QRCommand) {
        switch (command) {
            case "start":
                this.start();
                break;
            case "stop":
                this.stop();
                break;
        }
    }

    async start() {
        this.nextCommand = void 0;
        if (
            this._status &&
            ["starting", "started", "play", "playing"].includes(this._status)
        )
            return;

        if (this._status === "stopping") {
            this.nextCommand = "start";
        } else {
            try {
                await this.startScanner();
            } catch (e) {
                console.error(e);
            }

            this.completedCommand();
        }
    }

    async startScanner() {
        this.status = "starting";
        await this._qrScanner?.start();
        this.status = "started";
    }

    async stop() {
        this.nextCommand = void 0;
        if (this._status === "stop" || this._status === "stopping") return;

        if (this._status && ["starting", "play"].includes(this._status)) {
            this.nextCommand = "stop";
        } else {
            try {
                await this.stopScanner();
            } catch (e) {
                console.error(e);
            }
            this.completedCommand();
        }
    }

    async stopScanner() {
        this.status = "stopping";
        document
            .querySelectorAll(".scan-region-highlight")
            .forEach((element) => {
                element.remove();
            });
        await this._qrScanner?.pause();
        this._qrScanner?.stop();
        this.status = "stopped";
    }

    getQRScanner(
        videoElement: HTMLVideoElement,
        processResult: ProcessResult
    ): QrScanner {
        const qrScanner = new QrScanner(
            videoElement,
            (result) => {
                processResult(result);
            },
            {
                onDecodeError: () => {},
                highlightScanRegion: true,
                highlightCodeOutline: true,
            }
        );

        return qrScanner;
    }
}

const controller = new QrScannerController();

interface Props {
    processQrResult: ProcessResult;
    getVenuesQueryState: QueryState | undefined;
}

export default function QRCodeScanner(props: Props) {
    controller.processResult = props.processQrResult;

    const intitaliseCamera = useCallback(() => {
        const qrCodeVideo = document.getElementById("qrCodeLoginScanner");
        if (qrCodeVideo) {
            controller.videoElement = qrCodeVideo as HTMLVideoElement;
            let videoMaxHeight = viewportHeightAvailable(qrCodeVideo);
            if (videoMaxHeight) {
                qrCodeVideo.style.maxHeight = videoMaxHeight - 70 + "px";
            }

            if (!props.getVenuesQueryState?.isLoading) {
                controller.start();
            }
        }
    }, [props.getVenuesQueryState]);

    React.useLayoutEffect(() => {
        intitaliseCamera();

        return () => {
            controller.stop();
        };
    }, [intitaliseCamera]);

    return (
        <div>
            <p className={styles.container}>
                IMPORTANT: If your browser is asking for permission to use your device's camera, you will need to <a href="">choose allow</a> in order scan your QR code below.  
                Once the video stream is visible, position the QR code within the video to login.
            </p>
            <video id="qrCodeLoginScanner" poster="/web-camera.png" className={styles.videoElement}></video>
            <p className={styles.container}>
                Don't have a QR code? Get it <a target="_blank" rel="noopener noreferrer" href="https://setup.chomp.nz">here</a>.
            </p>
        </div>
    );
}
