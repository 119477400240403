import { useAppDispatch, useAppSelector } from "app/hooks";
import {
    setActiveScreen,
    selectActiveScreen,
    popActiveScreen,
    selectPreviousScreen,
    setActiveTasksScreen,
    selectShowSidebar,
    setShowSidebar,
} from "app/appSlice";
import { selectVenueData } from "features/venue/venueSlice";
import { selectBranchName } from "features/environment/envSlice";
import { getStore } from "app/store";
import { useCallback } from "react";
import { version } from "VERSION";
import { getBuildVersion } from "app/util";

export interface HeaderData {
    venueName: string;
    showBackButton?: boolean;
    showSidebar: boolean;
    buildVersion: string;
    backButtonOnClick?: () => void;
    resetTaskScreen: () => void;
    adminOnClick: () => void;
    toggleSidebar: () => void;
}

const setAdminScreenActive = () => {
    getStore().dispatch(setActiveScreen("admin"));
};

const resetTaskScreen = () => {
    getStore().dispatch(setActiveTasksScreen({}));
};

export default function useHeader() {
    const venueData = useAppSelector(selectVenueData);
    const dispatch = useAppDispatch();
    const showSidebar = useAppSelector(selectShowSidebar);
    const buildVersion = getBuildVersion();
    const previousScreen = useAppSelector(selectPreviousScreen);
    let showBackButton = false;
    if (previousScreen) {
        showBackButton = true;
    }
    let venueName;
    if (venueData) {
        if (venueData.draft) {
            venueName = venueData.venueName + " - DRAFT";
        } else {
            venueName = venueData.venueName;
        }
    }

    const backButtonOnClick = useCallback(() => {
        dispatch(popActiveScreen());
    }, [dispatch]);

    const toggleSidebar = useCallback(() => {
        dispatch(setShowSidebar(!showSidebar));
    }, [dispatch, showSidebar]);

    return {
        venueName,
        showBackButton,
        showSidebar,
        buildVersion,
        resetTaskScreen,
        backButtonOnClick: showBackButton ? backButtonOnClick : undefined,
        adminOnClick: setAdminScreenActive,
        toggleSidebar,
    };
}
