import { QueryStatus } from "@reduxjs/toolkit/dist/query"

export interface QueryState {
    isLoading: boolean;
    isFetching: boolean;
    isSuccess: boolean;
    error?: string
}

export const loadedQueryState: QueryState = {
    isLoading: false,
    isFetching: false,
    isSuccess: true
};

export interface RTKQueryState {
    status: QueryStatus;
    data?: any;
    error?: any;
}

export const getQueryState = (state: RTKQueryState): QueryState => {
    let error;
    if (state.error && 'error' in state.error) {
        error = state.error;
    }
    return {
        isLoading: state.status === QueryStatus.pending,
        isFetching: state.data && state.status === QueryStatus.pending,
        isSuccess: state.data,
        error: error
    }
}