import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { VenueData } from "./types";

export interface VenueState {
    data?: VenueData;
    id?: number;
}

const initialState: VenueState = {};

export const venueSlice = createSlice({
    name: "venue",
    initialState,
    reducers: {
        setId: (state: VenueState, action: PayloadAction<number>) => {
            state.id = action.payload;
        },
        setData: (state: VenueState, action: PayloadAction<VenueData>) => {
            state.data = action.payload;
        },
        resetState: (state: VenueState, action: PayloadAction<undefined>) => {
            return initialState;
        },
    },
});

export const { setId, setData } = venueSlice.actions;

export const selectVenueId = (state: RootState): number | undefined =>
    state.venue.id;
export const selectVenueData = (state: RootState): VenueData | undefined =>
    state.venue.data;
export const selectHasTaskSignoff = (state: RootState): boolean | undefined =>
    state.venue.data?.hasTaskSignoff;
export const selectHasSetData = (state: RootState) =>
    state.venue.data !== initialState.data;

export default venueSlice.reducer;
