import React from "react";
import styles from "./Header.module.scss";
import useHeader from "./useHeader";
import Sidebar from "features/sidebar/Sidebar";
import HeaderButton from "./HeaderButton";
import ActiveStaff from "features/staff/ActiveStaff";
import { Back } from "icons/icons";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

export default function Header() {
    const {
        venueName,
        showSidebar,
        buildVersion,
        backButtonOnClick,
        toggleSidebar,
    } = useHeader();

    const expand = false;

    return (
        <Navbar
            key="navbar"
            bg="white"
            expand={expand}
            expanded={showSidebar}
            className={styles.Header + " p-4"}
        >
            <Container fluid style={{ position: "relative" }}>
                {backButtonOnClick && (
                    <HeaderButton icon={<Back />} onClick={backButtonOnClick}>
                        Back
                    </HeaderButton>
                )}
                {!backButtonOnClick && (
                    <>
                        <Navbar.Toggle
                            aria-controls={`offcanvasNavbar`}
                            onClick={toggleSidebar}
                        />
                        <h3 className={styles.venueName}>{venueName}</h3>
                    </>
                )}

                <div className={styles.logoContainer}>
                    <img
                        src="/Chomp-Logo.png"
                        alt="Chomp"
                        className={styles.logo}
                    />
                </div>

                <div className={styles.staffVersionContainer}>
                    <ActiveStaff />
                    <p className={styles.version}>v{buildVersion}</p>
                </div>

                <Navbar.Offcanvas
                    id={`offcanvasNavbar`}
                    aria-labelledby={`offcanvasNavbarLabel`}
                    placement="start"
                >
                    <Sidebar toggleSidebar={toggleSidebar} />
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
}
