import type { ClosedDays } from "./types";

export const isClosed = (closedDays: ClosedDays[], date?: Date) => {
    if (!date) {
        date = normalise(new Date());
    }

    let closed = false;
    for (let day of closedDays) {
        const start = normalise(new Date(day.start));
        const end = normalise(new Date(day.end));
        if (date >= start && date <= end) {
            closed = true;
            break;
        }
    }
    return closed;
};

function normalise(date: Date): Date {
    return new Date(date.setHours(0, 0, 0, 0));
}
