import { useCallback } from "react";
import { RootState } from "./store";
import { selectVenueDataQueryState } from "services/appService";
import {
    selectVenueData,
    selectHasSetData as hasSetVenueData,
} from "features/venue/venueSlice";
import {
    selectIsLoggedIn,
    selectLoginError,
} from "features/loginToken/loginTokenSlice";
import {
    selectActiveVenueId,
    selectActiveScreen,
    selectShowUserSelect,
    selectShowSignoutConfirmation,
    selectShowResetConfirmation,
    selectShowDiaryQueue,
    setHasBeenIdle,
    setShowSignoutConfirmation,
    setShowResetConfirmation,
    setShowDiaryQueue,
    resetState,
    selectServerErrors,
    selectAppToasts,
    removeAppToast,
    setError,
    selectError,
} from "./appSlice";
import { selectHasSetData as hasSetTaskData } from "features/tasks/tasksSlice";
import { selectHasSetData as hasSetSuppliersData } from "features/suppliers/suppliersSlice";
import type { screen } from "./types";
import { selectEnv } from "features/environment/envSlice";
import { VenueData } from "features/venue/types";
import { useAppSelector } from "./hooks";
import { appDispatch } from "./util";
import type { QueryState } from "services/util";
import type { AppToast } from "app/types";

interface AppData {
    venueData?: VenueData;
    venueQueryState?: QueryState;
    venueId?: number;
    activeScreen: screen;
    loggedIn: boolean;
    loginError?: string;
    showUserSelect: boolean;
    showSignoutConfirmation: boolean;
    showResetConfirmation: boolean;
    showDiaryQueue: boolean;
    hasSetAllData: boolean;
    toasts?: AppToast[];
    error?: string;
    onIdle: () => void;
    onCancelShowSignoutConfirmation: () => void;
    onSignout: () => void;
    onCancelShowResetConfirmation: () => void;
    onReset: () => void;
    dimissToast: (toast: AppToast) => void;
    onDismissError: () => void;
    onCloseDiaryQueue: () => void;
}

function hasSetData(state: RootState): boolean {
    return (
        hasSetVenueData(state) &&
        hasSetTaskData(state) &&
        hasSetSuppliersData(state)
    );
}

export const useApp = (): AppData => {

    const onIdle = useCallback(() => {
        appDispatch(setHasBeenIdle(true));
    }, []);
    const env = useAppSelector(selectEnv);
    const loggedIn = useAppSelector(selectIsLoggedIn);

    const venueId = useAppSelector(selectActiveVenueId);
    const venueQueryState = useAppSelector((state: RootState) =>
        selectVenueDataQueryState(state)
    );
    const showUserSelect = useAppSelector(selectShowUserSelect);
    const showSignoutConfirmation = useAppSelector(
        selectShowSignoutConfirmation
    );
    const showDiaryQueue = useAppSelector(selectShowDiaryQueue);
    const serverErrors = useAppSelector(selectServerErrors);
    const error = useAppSelector(selectError);
    const hasSetAllData = useAppSelector(hasSetData);
    const toasts = useAppSelector(selectAppToasts);
    let loginError = useAppSelector(selectLoginError);
    if (
        !loginError &&
        !hasSetAllData &&
        serverErrors &&
        Object.keys(serverErrors).length > 0
    ) {
        loginError =
            "There was an error loading the venue's data. Please try again later.";
    }

    const onSignout = useCallback(() => {
        appDispatch(resetState());
    }, []);
    const onCancelShowSignoutConfirmation = useCallback(() => {
        appDispatch(setShowSignoutConfirmation(false));
    }, []);
    const onReset = useCallback(() => {
        appDispatch(resetState());
    }, []);
    const onCancelShowResetConfirmation = useCallback(() => {
        appDispatch(setShowResetConfirmation(false));
    }, []);
    const dimissToast = useCallback((toast: AppToast) => {
        appDispatch(removeAppToast(toast));
    }, []);
    const onDismissError = useCallback(() => {
        appDispatch(setError());
    }, []);
    const onCloseDiaryQueue = useCallback(() => {
        appDispatch(setShowDiaryQueue(false));
    }, []);
    const showResetConfirmation = useAppSelector(selectShowResetConfirmation);
    let venueData = useAppSelector(selectVenueData);
    const activeScreen = useAppSelector(selectActiveScreen);

    if (!env || !venueId || !venueQueryState) {
        return {
            loggedIn,
            venueData,
            activeScreen: activeScreen,
            showUserSelect,
            showSignoutConfirmation,
            showResetConfirmation,
            showDiaryQueue,
            hasSetAllData,
            toasts,
            onCancelShowSignoutConfirmation,
            onCancelShowResetConfirmation,
            onSignout,
            onReset,
            onIdle,
            dimissToast,
            onDismissError,
            onCloseDiaryQueue,
        };
    }

    return {
        loggedIn,
        loginError,
        venueData,
        venueQueryState,
        venueId,
        activeScreen,
        showUserSelect,
        showSignoutConfirmation,
        showResetConfirmation,
        showDiaryQueue,
        hasSetAllData,
        toasts,
        onIdle,
        onCancelShowSignoutConfirmation,
        onCancelShowResetConfirmation,
        onSignout: onSignout,
        onReset,
        dimissToast,
        onDismissError,
        onCloseDiaryQueue,
    };
};
